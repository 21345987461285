<template>
  <div class="card">
      <div class="card-body">
        <div class="vertical-tabs">
          <div class="nav nav-tabs" id="nav-tab" >
            <a v-if="show_bank" class="nav-item nav-link" @click="tabActive = 'bank'" v-bind:class="{'active':tabActive=='bank'}" href="javascript:void(0)">{{$trans('Bank')}}</a>
            <a v-if="show_bank" class="nav-item nav-link" @click="tabActive = 'qrcode'" v-bind:class="{'active':tabActive=='qrcode'}" href="javascript:void(0)">{{$trans('Qrcode')}}</a>
            <!--<a v-if="show_momo" class="nav-item nav-link" @click="tabActive = 'momo'" v-bind:class="{'active':tabActive=='momo'}" href="javascript:void(0)">{{$trans('Momo')}}</a>-->
            <a v-if="show_Crypto" class="nav-item nav-link" @click="tabActive = 'crypto'" v-bind:class="{'active':tabActive=='crypto'}" href="javascript:void(0)">{{$trans('Crypto Wallets')}}</a>
          </div>
          <div class="tab-content" id="nav-tabContent">
            <div v-if="tabActive == 'bank' && show_bank" class="tab-pane active">
              <deposit-banks></deposit-banks>
            </div>

            <div v-if="tabActive == 'qrcode' && show_bank" class="tab-pane active">
              <qrcode-banks></qrcode-banks>
            </div>

            <!--
            <div v-if="tabActive == 'momo' && show_momo" class="tab-pane active">
              <deposit-momo></deposit-momo>
            </div>-->
            <div v-if="tabActive == 'crypto' && show_Crypto" class="tab-pane active">
              <deposit-crypto></deposit-crypto>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
export default {
  name: "DepositAccount",
  data:function (){
      return {
        tabActive:'bank',
        current_currency:this.$root.current_currency,
      }
  },
  computed:{
      show_momo:function (){
        if( this.current_currency !='VND'){
          return  false
        }
        return true
      },
      show_bank:function (){
          let method_support = ['VND','THB','IDR'];
          if( method_support.includes(this.current_currency)){
              return  true
          }
          return false;
      },
      show_Crypto:function (){
        let method_support = ['BTC'];
        if( method_support.includes(this.current_currency)){
          return  true
        }
        return false;
      }
  }
}
</script>

<style scoped>

</style>