<template>
  <div class="deposit-crypto">
    <div class="d-flex">
      <h4 class="mb-0 mr-auto">{{$trans('Crypto wallets')}}</h4>
      <button v-if="$can('add-deposit-accounts')" @click="addForm = true" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
        {{$trans('Add new')}}
      </button>
    </div>
    <hr>
    <slot v-if="deposit_accounts && deposit_accounts.data">
      <div class="table-responsive">
        <div class="list-info mb-3">
          <small>{{$trans('Results')}}: <strong>{{deposit_accounts.data.length}}</strong> {{$trans('of')}} <strong>{{deposit_accounts.total}}</strong></small>
        </div>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>{{$trans('Wallet Address')}}</th>
            <th>{{$trans('Status')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(account,index) in deposit_accounts.data" v-bind:key="index">
            <td>{{account.wallet_address}}</td>
            <td>
              <span v-if="account.status == 1" class="badge badge-primary badge-pill">{{$trans('Active')}}</span>
              <span v-if="account.status == 0" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
            </td>
            <td class="text-right">
              <b-dropdown
                  split
                  split-variant="outline-primary"
                  variant="primary"
                  :text="$trans('Actions')"
                  class="m-2"
                  size="sm"
              >
                <slot>
                  <b-dropdown-item :disabled="!$can('edit-deposit-accounts')"  @click="edit(account)" href="#">{{$trans('Edit')}}</b-dropdown-item>
                  <b-dropdown-item  :disabled="!$can('delete-deposit-accounts')"  @click="destroy(account)" href="#">{{$trans('Delete')}}</b-dropdown-item>
                </slot>
              </b-dropdown>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <paginate v-if="deposit_accounts && deposit_accounts.data && deposit_accounts.last_page"
                :page-count="deposit_accounts.last_page"
                :click-handler="paginate"
                :prev-text="$trans('Previous')"
                :next-text="$trans('Next')"
                :page-class="'page-item'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :container-class="'pagination'">
      </paginate>
    </slot>

    <modal v-bind:width="'700px'" v-bind:height="'100vh'" v-if="addForm">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Add account')}}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{$trans('Wallet address')}} (*)</label>
          <input v-model="addFields.wallet_address" class="form-control" type="text">
          <slot v-if="errors && errors['account_phone']">
            <div class="text-danger" v-for="error in errors['wallet_address']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="add_status" type="checkbox" v-model="addFields.status">
            <label class="form-check-label" for="add_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['status']">
            <div class="text-danger" v-for="error in errors['status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="store" class="btn btn-primary m-t-20">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add new')}}
        </button>
      </slot>
    </modal>
    <modal v-bind:width="'700px'" v-bind:height="'100vh'" v-if="editForm">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Edit account')}}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{$trans('Wallet address')}} (*)</label>
          <input v-model="updateFields.wallet_address" class="form-control" type="text">
          <slot v-if="errors && errors['account_phone']">
            <div class="text-danger" v-for="error in errors['wallet_address']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="update_status" type="checkbox" v-model="updateFields.status">
            <label class="form-check-label" for="update_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['status']">
            <div class="text-danger" v-for="error in errors['status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update" class="btn btn-primary m-t-20">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Update')}}
        </button>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DepositCrypto",
  data:function (){
    return {
      addForm:false,
      editForm:false,
      addFields:{
        status:1,
        wallet_address:'',
      },
      alert:null,
      errors:{},
      current_currency:this.$root.current_currency,
      process:false,
      filterQuery:{
        page:1,
      },
      deposit_accounts:null,
      updateFields:{},
      type:'crypto'
    }
  },
  methods:{
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index:function (){
      this.filterQuery.type = this.type
      axios({url: this.$root.$data.api_url + '/api/deposit/account',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.deposit_accounts = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    store:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.addFields
        data.type = this.type
        axios({url: this.$root.$data.api_url + '/api/deposit/account', data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (account){
      axios({url: this.$root.$data.api_url + '/api/deposit/account/'+account._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.editForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    update:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.updateFields
        data.type = this.type
        axios({url: this.$root.$data.api_url + '/api/deposit/account/'+data._id, data:data,method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.editForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (account){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/deposit/account/' + account._id, method: 'DELETE'})
          .then(() => {
            this.index()
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  computed:{
    bank_supports:function (){
      let banks = this.$root.$data.global_settings.bank_supports
      if( ! banks){
        return  [];
      }
      let bank_supports =[]

      for (const [key, value] of Object.entries(banks)) {
        let item ={
          id:key,
          text:value
        }
        bank_supports.push(item)
      }

      return bank_supports
    },
  },
  created() {
    this.index()
  }
}
</script>

<style scoped>

</style>