import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Axios from 'axios'

import AxiosPlugin from 'vue-axios-cors';
Vue.use(AxiosPlugin)

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import device from "vue-device-detector"

Vue.use(device)

import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);

import VueGoogleCharts from 'vue-google-charts'

Vue.use(VueGoogleCharts)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'
import './assets/fontawesome/css/all.css'

import Paginate from 'vuejs-paginate'

Vue.component('paginate', Paginate)
import VueNumeric from 'vue-numeric'

Vue.use(VueNumeric)

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.use(DatePicker)


Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.prototype.$http.defaults.headers.common['Content-type'] = 'application/json'

const lang = localStorage.getItem('lang');

if (lang) {
    Vue.prototype.$http.defaults.headers.common['language'] = lang;
}
const currency = localStorage.getItem('currency');

if (currency) {
    Vue.prototype.$http.defaults.headers.common['currency'] = currency;
}
import VueGallery from 'vue-gallery';
Vue.use(VueGallery)
Vue.component('gallery', VueGallery)

import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

import i18n from './components/mixins/i18n.vue';
Vue.mixin(i18n);
import Helpers from './components/mixins/Helpers.vue';

Vue.mixin(Helpers);

Vue.component('sidebar', require('./components/Sidebar.vue').default);
Vue.component('modal', require('./components/Modal.vue').default);
Vue.component('system-settings', require('./components/settings/System.vue').default);
Vue.component('deposit-settings', require('./components/settings/Deposit.vue').default);
Vue.component('payout-settings', require('./components/settings/Payout.vue').default);
Vue.component('fee-settings', require('./components/settings/Fee.vue').default);
Vue.component('deposit-account', require('./components/settings/DepositAccount.vue').default);
Vue.component('deposit-banks', require('./components/settings/DepositBank.vue').default);
Vue.component('qrcode-banks', require('./components/settings/QrcodeBank.vue').default);
Vue.component('deposit-momo', require('./components/settings/DepositMomo.vue').default);
Vue.component('deposit-crypto', require('./components/settings/DepositCrypto.vue').default);
Vue.component('merchant-settings', require('./components/Merchant.vue').default);
Vue.component('momo-history', require('./components/momo-wallets/History.vue').default);
Vue.component('momo-transfer', require('./components/momo-wallets/Trasfer.vue').default);
Vue.component('momo-withdraw', require('./components/momo-wallets/Withdraw.vue').default);
Vue.component('momo-history-db', require('./components/momo-wallets/HistoryDb.vue').default);
Vue.component('personal-information', require('./components/Auth/PersonalInformation').default);
Vue.component('Google2FA', require('./components/Auth/Google2FA').default);
Vue.component('zalo-history', require('./components/zalo/History').default);
Vue.component('zalo-transfer', require('./components/zalo/Transfer').default);
Vue.component('zalo-withdraw', require('./components/zalo/Withdraw').default);
Vue.component('zalo-history-db', require('./components/zalo/HistoryDb').default);
Vue.component('report-ib', require('./components/reports/Ib').default);
Vue.component('report-ib-screenshots', require('./components/reports/IbScreenshots').default);
Vue.component('report-momo', require('./components/reports/Momo').default);
Vue.component('report-zalo', require('./components/reports/Zalo').default);
Vue.component('report-ib-transaction', require('./components/reports/IbReport').default);
Vue.component('widget-momo-report', require('./components/widgets/MomoReport').default);
Vue.component('widget-zalo-report', require('./components/widgets/ZaloReport').default);
Vue.component('widget-auto-status', require('./components/widgets/AutoSatus').default);
Vue.component('widget-ib-online', require('./components/widgets/IbOnline').default);
Vue.component('widget-ib-report', require('./components/widgets/IbReport').default);
Vue.component('deposit-bank-report', require('./components/reports/DepositBank').default);


new Vue({
    render: h => h(App),
    router,
    store,
    data: function () {
        return {
            api_url: process.env.VUE_APP_API_URL,
            app_name: process.env.VUE_APP_NAME,
            global_settings:{}
        }
    },
    methods: {
        get_global_settings:function (){
            Axios({url: this.$root.$data.api_url + '/api/settings', params: {}, method: 'GET'})
                .then(resp => {
                    this.global_settings = resp.data;
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
    computed:{
        isMobile: function () {
            return this.$device.mobile;
        },
        current_currency:function () {
            return this.$store.getters.currency;
        },
        current_language:function () {
            return this.$store.getters.lang;
        },
    },
    created() {
        Axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    let currentUrl = window.location
                    let domain = window.location.origin;
                    this.$store.dispatch('logout')
                    //this.$router.push('/login?return_url='+currentUrl)
                    window.location.replace(domain+'/login?return_url='+currentUrl);
                }
                if (error.response.status === 419) {
                    this.$router.push('/security/google2fa')
                }
            }
            throw  error;
        })
        this.get_global_settings();
    }
}).$mount('#app')
