<template>
  <div class="deposit card">
    <div class="card-header">
      <h2>{{ $trans('Bank_History') }}</h2>
    </div>
    <div class="d-flex mb-3">
      <input @change="filter" v-model="filterQuery.description" :placeholder="$trans('Description')" class="form-control w-150 mr-3" type="text">
      <input @change="filter" v-model="filterQuery.account" :placeholder="$trans('Account')" class="form-control w-150 mr-3" type="text">
      <select @change="filter" v-model="filterQuery.bank_code" class="form-control w-120 mr-3">
        <option value="">{{ $trans('All Bank') }}</option>
        <option value="TPB">{{ $trans('TPB') }}</option>
        <option value="ACB">{{ $trans('ACB') }}</option>
      </select>
    </div>
    <div class="card-body">
      <slot v-if="transactions && transactions.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ transactions.data.length }}</strong> {{ $trans('of') }} <strong>{{ transactions.total }}</strong></small>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Trans ID') }}</th>
              <th>{{ $trans('Reference Code') }}</th>
              <th>{{ $trans('Current Balance') }}</th>
              <th>{{ $trans('Trans Date') }}</th>
              <th>{{ $trans('Bank Code') }}</th>
              <th>{{ $trans('Bank Account') }}</th>
              <th>{{ $trans('Description') }}</th>
              <th>{{ $trans('Type') }}</th>
              <th>{{ $trans('Confirmed') }}</th>
              <th class="text-right">{{ $trans('Amount') }}</th>
              <th>{{ $trans('Account Name') }}</th>
              <th>{{ $trans('Account Number') }}</th>
              <th>{{ $trans(' Bank Name') }}</th>
              <th>{{ $trans('Date') }}</th>

              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transaction,index) in transactions.data" v-bind:key="index">
              <td>{{ transaction.transId }}</td>
              <td>{{ transaction.reference }}</td>
              <td>
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="current_currency"
                    v-bind:value="transaction.currentBalance"
                    v-bind:read-only="true"
                    v-bind:precision="$get_precision()">
                </vue-numeric>

              </td>
              <td>{{ transaction.transDate }}</td>
              <td> <span class="mr-1 mt-1 badge badge-info">{{transaction.bank_code}}</span> </td>
              <td>
                <slot>{{ transaction.account}}</slot>
              </td>
              <td>
                <slot>{{ transaction.description}}</slot>
              </td>
              <td>
                <span v-if="transaction.trans_category == 'DBIT' " class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Money OUT') }}</span>
                <span v-else-if="transaction.trans_category == 'CRDT' " class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Money In') }}</span>
                <span v-else class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Unknow') }}</span>
              </td>
              <td>
                <span v-if="transaction.confirmed " class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Confirmed') }}</span>
              </td>
              <td class="text-right text-success">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="current_currency"
                    v-bind:value="transaction.amount"
                    v-bind:read-only="true"
                    v-bind:precision="$get_precision()">
                </vue-numeric>
              </td>
              <td>
                <slot>{{ transaction.receiver_name}}</slot>
              </td>
              <td>
                <slot>{{ transaction.receiver_account}}</slot>
              </td>
              <td>
                <slot>{{ transaction.receiver_bank}}</slot>
              </td>
              <td>{{ transaction.created_at }}</td>


            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="transactions && transactions.data && transactions.last_page"
                  :page-count="transactions.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
    <modal v-if="showDetail && $can('view-deposit') && transaction" v-bind:width="'80%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Transaction detail') }}
          <button @click="showDetail = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <nav class="mb-3">
          <div class="nav nav-tabs" id="nav-tab">
            <a class="nav-item nav-link" @click="tabActive = 'detail'" v-bind:class="{'active':tabActive=='detail'}"
               href="javascript:void(0)">{{ $trans('Detail') }}</a>
            <a class="nav-item nav-link" @click="tabActive = 'logs'" v-bind:class="{'active':tabActive=='logs'}"
               href="javascript:void(0)">{{ $trans('Logs') }}</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div v-if="tabActive == 'detail'" class="tab-pane active">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th>{{ $trans('Ref Number') }}</th>
                <td>{{ transaction.ref_number }}</td>
              </tr>
              <tr>
                <th>{{ $trans('Method') }}</th>
                <td>
                  <span v-if="transaction.method=='IB'"
                        class="mr-1 mt-1 badge badge-info badge-pill">{{ $trans('Internet banking') }}</span>
                  <span v-if="transaction.method=='BANK_TRANSFER'"
                        class="mr-1 mt-1 badge badge-primary badge-pill">{{ $trans('Bank transfer') }}</span>
                  <span v-if="transaction.method=='MOMO'"
                        class="mr-1 mt-1 badge badge-dark badge-pill">{{ $trans('MOMO wallet') }}</span>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Order ID') }}</th>
                <td>{{ transaction.order_id }}</td>

              </tr>
              <slot v-if="show_account_info">
                <tr>
                  <th>{{ $trans('Deposit account') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.name }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Account name') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_name }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Account number') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_number }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Sender Account No') }}</th>
                  <td>
                    <slot v-if="transaction.send_account_number">
                      {{ transaction.send_account_number }}
                    </slot>
                    <slot v-else>-</slot>
                  </td>
                </tr>
              </slot>
              <slot v-else>
                  <tr>
                    <th>{{$trans('Wallet Address')}}</th>
                    <td>
                      <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.wallet_address }}</slot>
                    </td>
                  </tr>
              </slot>
              <tr>
                <th>{{ $trans('Trading Code') }}</th>
                <td>
                  <slot v-if="transaction.trading_code">
                    {{ transaction.trading_code }}
                  </slot>
                  <slot v-else>-</slot>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Amount') }}</th>
                <td class=" text-success">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="current_currency"
                      v-bind:value="transaction.amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Date') }}</th>
                <td>{{ transaction.created_at }}</td>

              </tr>
              <tr>
                <th>{{ $trans('Status') }}</th>
                <td>
                  <span v-if="transaction.status==1"
                        class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                  <span v-if="transaction.status==2"
                        class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Pending') }}</span>
                  <span v-if="transaction.status==3"
                        class="mr-1 mt-1 badge badge-info badge-pill">{{ $trans('Processing') }}</span>
                  <span v-if="transaction.status==0"
                        class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                  <span v-if="transaction.status==-1"
                        class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                  <span v-if="transaction.status<-1"
                        class="mr-1 mt-1 badge badge-danger badge-pill">{{ deposit_status[transaction.status] }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="$can('confirm-deposit')" class="form-actions">
              <slot v-if="transaction.status <=0 && transaction.with_account == 'no'">
                <div class="alert alert-info">
                  {{ $trans('In case the bank has received the money but the system does not update. Please update manually ') }}
                </div>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                  {{ alert.message }}
                </div>
                <div class="form-group">
                  <label>{{ $trans('Reason') }}(*)</label>
                  <input v-model="reason" class="form-control" type="text">
                  <slot v-if="errors && errors['reason']">
                    <div class="text-danger" v-for="error in errors['reason']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <button @click="confirm_transaction(transaction._id)" class="btn btn-primary">
                  <i v-if="process" class="fa fa-spinner fa-spin"></i>
                  {{ $trans('Confirm Transaction') }}
                </button>
              </slot>
            </div>
          </div>
          <div v-if="tabActive == 'logs'" class="tab-pane active">
            <table v-if="$can('view-deposit-logs')" class="table table-striped">
              <tbody>
              <tr v-if="transaction.logs && transaction.logs.length">
                <th style="vertical-align: top">{{ $trans('Logs') }}</th>
                <td>
                  <span class="d-block" v-for="(log,k) in transaction.logs" v-bind:key="k">{{ log }}</span>
                </td>
              </tr>
              <tr v-if="transaction.auto_logs && transaction.auto_logs.length && decode_auto_logs">
                <th style="vertical-align: top">{{ $trans('Auto Logs') }}</th>
                <td>
                  <textarea style="width: 100%" class="" disabled v-model="decode_auto_logs"></textarea>
                </td>
              </tr>
              <tr>
                <th style="vertical-align: top">{{ $trans('Callback') }}</th>
                <td>
                  <slot v-if="transaction.callback">
                    <span class="d-block"><strong>Status:</strong> {{ transaction.callback.status }}</span>
                    <span class="d-block"><strong>Url:</strong> {{ transaction.callback.url }}</span>
                    <span class="d-block"><strong>Http Code:</strong> {{ transaction.callback.http_code }}</span>
                    <span class="d-block"><strong>Time:</strong> {{ transaction.callback.time }}</span>
                    <span class="d-block">
                        <strong>{{$trans('Input')}}:</strong>
                        <pre>
                        {{ transaction.callback.input }}
                        </pre>
                      </span>
                    <span class="d-block">
                        <strong>{{$trans('Response')}}:</strong>
                        <pre>
                        {{ transaction.callback.response }}
                        </pre>
                      </span>
                  </slot>
                  <span class="d-block">
                      <button @click="re_callback(transaction._id)" class="btn btn-primary">
                        <i v-if="process" class="fa fa-spinner fa-spin"></i>
                        {{$trans('Re-Callback')}}
                      </button>
                  </span>
                </td>
              </tr>
              <tr v-if="transaction.screenshots && transaction.screenshots.length">
                <th style="vertical-align: top">{{ $trans('Screenshots') }}</th>
                <td>
                  <gallery :images="transaction.screenshots" :index="gallery_index"
                           @close="gallery_index = null"></gallery>
                  <span class="image text-success" @click="gallery_index = 1">{{ $trans(' View Screenshots') }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-warning">{{ $trans('You do not have permission') }}</div>
          </div>
        </div>

      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Deposit",
  data: function () {
    return {
      transactions: null,
      filterQuery: {
        page: 1,
        account: '',
        description: '',
        bank_code:'',
        filterby: 'month',
      },
      current_currency: this.$root.current_currency,
      transaction: null,
      showDetail: false,
      tabActive: 'detail',
      gallery_index: null,
      reason: '',
      errors: {},
      alert: null,
      process: false,

      export_transactions:null,
      labels:{
        'ref_number':'Ref Number',
        'method':'Method',
        'order_id':'Order ID',
        'client':'Client',
        'deposit_account':'Deposit account',
        'account_name':'Account name',
        'account_number':'Account number',
        'send_account_number':'Sender Account No',
        'amount':'Amount',
        'created_at':'Date',
        'status':'Status'
      }
    }
  },
  methods: {
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/deposit/bank_history', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.transactions = resp.data;
            console.log(resp.data)
          })
          .catch(err => {
            console.log(err)
          })
    },
    do_export:function ( ){
      if (this.process == false) {
        this.process = true;
        this.export_transactions = null;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/deposit/export', params: this.filterQuery, method: 'GET'})
            .then(resp => {
              this.export_transactions = resp.data;
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    detail: function (id) {
      this.showDetail = true
      this.tabActive = 'detail'
      axios({url: this.$root.$data.api_url + '/api/deposit/transactions/' + id, params: {}, method: 'GET'})
          .then(resp => {
            this.transaction = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
    this.index()
    // Real time
  },
}
</script>

<style scoped>

</style>