import { render, staticRenderFns } from "./IbScreenshots.vue?vue&type=template&id=0b09c707&scoped=true&"
import script from "./IbScreenshots.vue?vue&type=script&lang=js&"
export * from "./IbScreenshots.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b09c707",
  null
  
)

export default component.exports