<template>
    <div>
        <div class="sidebar-head">
          <router-link to="/">
            <img class="img-logo" :src="require('../assets/logo.png')" alt="">
          </router-link>
        </div>
        <div class="user-info">
            <router-link to="/profile">
                <span class="avatar-logo">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                </span>
                 <span class="info">
                    <span class="name">{{user}}</span>
                    <span class="sub">
                      <slot v-if="roles">
                        <span v-for="(role,i) in roles" :key="i" class="badge badge-success">{{role}}</span>
                      </slot>
                      <slot v-else>-</slot>
                    </span>
                </span>
            </router-link>
        </div>
        <div class="d-flex mb-1">
            <div class="mr-1">
              <div class="currency-form">
                <select @change="change_currency"  class="form-control">
                  <option :selected="currency == current_currency" v-for="(currency,key) in currencies" v-bind:key="key" :value="currency">
                    {{currency}}
                  </option>
                </select>
              </div>
            </div>
            <div class="currency-form">
              <select v-model="current_language" @change="change_language"   class="form-control">
                <option value="en">English</option>
                <option value="vi">Tiếng Việt</option>
                <option value="zh">中文</option>
              </select>
            </div>
        </div>

        <div class="sidebar-menu-content">
            <ul class="sidebar-menu">
              <li>
                <router-link to="/">
                  <span class="icon">
                      <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                  </span>
                  <span class="text">{{$trans('Dashboard')}}</span>
                </router-link>
              </li>
                <li v-if="$can(['view-roles','add-roles','edit-roles','delete-roles'])">
                    <router-link to="/roles">
                        <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        </span>
                        <span class="text">{{$trans('User roles')}}</span>
                    </router-link>
                </li>
                <li v-if="$can(['view-users','add-users','edit-users','delete-users'])">
                    <router-link to="/users">
                        <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                        </span>
                        <span class="text">{{$trans('Users')}}</span>
                    </router-link>
                </li>
                <li v-if="$can(['view-deposit','confirm-deposit'])">
                  <router-link to="/deposit">
                    <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="12" y1="18" x2="12" y2="12"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
                    </span>
                    <span class="text">{{$trans('Deposit')}}</span>
                  </router-link>
                </li>
              <li v-if="$can(['view-deposit','confirm-deposit'])">
                <router-link to="/bank_history">
                    <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="12" y1="18" x2="12" y2="12"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
                    </span>
                  <span class="text">{{$trans('Bank History')}}</span>
                </router-link>
              </li>
                <li v-if="$can(['view-payout','confirm-payout'])">
                  <router-link to="/payout">
                      <span class="icon">
                          <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="9" y1="15" x2="15" y2="15"></line></svg>
                      </span>
                    <span class="text">{{$trans('Payout')}}</span>
                  </router-link>
                </li>
                <li v-if="$can(['view-notification'])">
                  <router-link to="/notification">
                          <span class="icon">
                              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line></svg>
                          </span>
                    <span class="text">{{$trans('Notification')}}</span>
                  </router-link>
                </li>
                <li v-if="$can(['view-momo-wallet','add-momo-wallet','edit-momo-history','delete-momo-history','transfer-momo-wallet']) && current_currency=='VND'">
                  <router-link to="/momoWallet">
                          <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="8" y1="12" x2="16" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line><line x1="12" y1="8" x2="12" y2="8"></line><circle cx="12" cy="12" r="10"></circle></svg>
                          </span>
                    <span class="text">{{$trans('Momo Wallet')}}</span>
                  </router-link>
                </li>
              <li v-if="$can(['view-zalopay','add-zalopay','edit-zalopay','delete-zalopay']) && current_currency=='VND'">
                <router-link to="/zalopay">
                    <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                    </span>
                  <span class="text">{{$trans('Zalopay')}}</span>
                </router-link>
              </li>
                <li v-if="$can(['view-reports'])">
                  <router-link to="/Reports">
                        <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                        </span>
                        <span class="text">{{$trans('Reports')}}</span>
                  </router-link>
                </li>
                <li>
                    <router-link to="/settings">
                        <span class="icon">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path></svg>
                        </span>
                        <span class="text">{{$trans('Settings')}}</span>
                    </router-link>
                </li>
                <li>
                  <router-link to="/thanhtoan">
                          <span class="icon">
                              <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                          </span>
                    <span class="text">{{$trans('Thanh Toán')}}</span>
                  </router-link>
                </li>
                <li class="menu-item">
                  <a href="javascript:void(0)" @click="logout">
                     <span class="icon">
                        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                    </span>
                    <span>{{$trans('Logout')}}</span>
                  </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        name: "Sidebar",
        data:function () {
            return {
              app_name:'NETPAY88 V2',
              current_currency:this.$root.current_currency,
              current_language:this.$root.current_language,
            }
        },
      methods:{
        logout: function () {
          this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })
        },
        change_currency:function (event){
          this.$store.dispatch('changeCurrency', event.target.value)
        },
        change_language:function (){
          this.$store.dispatch('setLang', event.target.value)
        }
      },
      computed:{
          user:function () {
              return this.$store.getters.user
          },
          roles:function (){
            let roles = JSON.parse(localStorage.getItem('roles'))
            if( ! roles){
                return  null;
            }
            return  roles;
          },
          currencies:function (){
            return this.$root.$data.global_settings.currencies_support;
          },
      }
    }
</script>

<style scoped>

</style>