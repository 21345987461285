<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex report-header">
        <h4 class="mb-0 mr-auto">{{ $trans('IB Screenshots') }}</h4>
      </div>
      <hr>
      <div class="d-flex mb-3">
        <select @change="filter" v-model="filterQuery.bank" class="form-control w-200 mr-3">
          <option value="all">{{ $trans('All bank') }}</option>
          <option v-for="(bank,key) in bank_supports" v-bind:key="key" :value="key">{{ bank }}</option>
        </select>
        <select @change="filter" v-model="filterQuery.status" class="form-control w-200 mr-3">
          <option value="all">{{ $trans('All status') }}</option>
          <option v-for="(status,key) in deposit_status" v-bind:key="key" :value="key">{{ status }}</option>
        </select>
        <select v-model="filterQuery.filterby" class="form-control w-200 mr-3" @change="filter">
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="year">This Year</option>
        </select>
      </div>
      <slot v-if="transactions && transactions.data">
        <div class="list-info mb-3">
          <small>{{ $trans('Results') }}: <strong>{{ transactions.data.length }}</strong> {{ $trans('of') }} <strong>{{
              transactions.total
            }}</strong></small>
        </div>
        <div class="screenshots-list row" v-if="transactions && transactions.data">
          <gallery v-if="screenshots" :images="screenshots" :index="gallery_index" @close="gallery_index = null"></gallery>
          <div class="image col-sm-1" v-for="(image, imageIndex) in transactions.data"  :key="imageIndex" >
            <slot v-if="image.screenshots && image.screenshots.length">
              <div @click="gallery(image.screenshots)">
                <img v-for="(img,k) in image.screenshots" :key="k"  :src="img" alt="" >
              </div>
              <span class="ref">
                {{image.ref_number}}<br>
                <span>{{ deposit_status[image.status] }}</span>
              </span>
            </slot>
            <slot v-else>
              <div>
                <img src="https://via.placeholder.com/107x73.png?text=NO%20IMG" alt="">
              </div>
              <span class="ref">{{image.ref_number}}<br>
                <span>{{ deposit_status[image.status] }}</span>
              </span>
            </slot>

          </div>
        </div>
        <paginate v-if="transactions && transactions.data && transactions.last_page"
                  :page-count="transactions.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "IbScreenshots",
  data: function () {
    return {
      filterQuery: {
        filterby: 'all',
        page: 1,
        bank:'all',
        status:'all'
      },
      transactions: null,
      gallery_index:null,
      screenshots:null
    }
  },
  methods: {
    filter:function (){
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/reports/ib/screenshots', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.transactions = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    gallery:function (screenshots){
      this.gallery_index = 0
      let s =[]
      screenshots.forEach(el=>{
        s.push(el)
      })
      this.screenshots = s
    }
  },
  created() {
    this.index()
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('deposit-channel');
    channel.bind('deposit-event', (e) => {
      if (this.current_currency == e.currency) {
        if (this.transactions && this.transactions.data) {
          if (e.action == 'created') {
            this.transactions.data.unshift(e.transaction);
          } else {
            var arr = this.transactions.data;
            var tmp_arr = [];
            var current_id = e.transaction._id;
            arr.forEach(function (el, index) {
              if (current_id == el._id) {
                tmp_arr[index] = e.transaction;
              } else {
                tmp_arr[index] = el;
              }
            });
            this.transactions.data = tmp_arr;
          }
        }
      }

    });
  },
  computed: {
    bank_supports: function () {
      return this.$root.$data.global_settings.bank_supports;
    },
    deposit_status: function () {
      return this.$root.$data.global_settings.deposit_status;
    },
  }
}
</script>

<style scoped>

</style>