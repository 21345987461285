<template>
  <div>
    <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
      {{alert.message}}
    </div>
    <div class="form-group">
      <label>{{$trans('Amount')}}</label>
      <vue-numeric class="form-control"
                   currency-symbol-position="suffix"
                   :currency="'VND'"
                   v-model="transferFields.amount"
                   v-bind:read-only="false"
                   v-bind:precision="0">
      </vue-numeric>
      <slot v-if="errors && errors['amount']">
        <div class="text-danger" v-for="error in errors['amount']"
             v-bind:key="error">
          <small>{{ error }}</small>
        </div>
      </slot>
    </div>
    <div v-if="banks" class="form-group">
      <label>{{$trans('Bank')}}</label>
      <select v-model="transferFields.bank" class="form-control">
        <option v-for="(bank,index) in banks" :key="index"  :value="index">
          {{bank.bankcode}} - {{bank.firstno}}xxx{{bank.lastno}}
        </option>
      </select>
      <slot v-if="errors && errors['bank']">
        <div class="text-danger" v-for="error in errors['bank']"
             v-bind:key="error">
          <small>{{ error }}</small>
        </div>
      </slot>
    </div>
    <div class="alert alert-info" v-if="bank_selected">
        Max value:
        <vue-numeric
            currency-symbol-position="suffix"
            :currency="'VND'"
            v-bind:value="bank_selected.maxvalue"
            v-bind:read-only="true"
            v-bind:precision="0">
        </vue-numeric>
        <br>
        Min value:
        <vue-numeric
            currency-symbol-position="suffix"
            :currency="'VND'"
            v-bind:value="bank_selected.minvalue"
            v-bind:read-only="true"
            v-bind:precision="0">
        </vue-numeric>
        <br>
        Fee rate:
        <vue-numeric
            currency-symbol-position="suffix"
            :currency="'VND'"
            v-bind:value="bank_selected.feerate"
            v-bind:read-only="true"
            v-bind:precision="0">
        </vue-numeric>
        <br>
    </div>
    <button @click="withdraw" class="btn btn-primary">
      <i v-if="process" class="fa fa-spinner fa-spin"></i>
      {{$trans('Submit')}}
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Withdraw",
  props: {
    account_id: String,
  },
  data: function () {
    return {
      filterQuery: {},
      withdraw_info:null,
      transferFields:{
          amount:0,
          bank:0
      },
      alert:null,
      errors:{},
      process:false
    }
  },
  methods: {
    get_banks: function () {
      axios({
        url: this.$root.$data.api_url + '/api/zalopay/withdraw_banks/' + this._props.account_id,
        params: this.filterQuery,
        method: 'GET'
      })
          .then(resp => {
            this.withdraw_info = resp.data.data.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    withdraw:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.transferFields
        data.data_atm = this.bank_selected
        axios({url: this.$root.$data.api_url + '/api/zalopay/withdraw/'+this._props.account_id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.get_banks()
  },
  computed:{
      banks:function (){
          if( !this.withdraw_info){
              return null
          }
          return  this.withdraw_info.pmctranstypes
      },
      bank_selected:function (){
        if( !this.banks ){
          return {}
        }
        return this.banks[this.transferFields.bank]
      }
  }
}
</script>

<style scoped>

</style>