<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex report-header">
        <h4 class="mb-0 mr-auto">{{ $trans('IB Reports') }}</h4>
        <div class="d-flex flex-row-reverse">
          <select v-model="filterQuery.filterby" class="custom-select w150" @change="index">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="month">This Month</option>
            <option value="last_month">Last Month</option>
            <option value="year">This Year</option>
          </select>
        </div>
      </div>
      <hr>
      <slot v-if="results">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{$trans('Bank')}}</th>
              <th class="nowrap text-right" v-for="(status,i) in deposit_status_with_key" :key="i">{{status.text}}</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(result,k) in results" :key="k">
                      <td class="nowrap"><strong>{{bank_supports[current_currency+'_'+result._id]}}</strong></td>
                      <td class="nowrap text-right" v-for="(status,i) in deposit_status_with_key" :key="i">
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency=""
                            v-bind:value="result[status.key]"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric> /
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency="%"
                            v-bind:value="result[status.key+'_percent']"
                            v-bind:read-only="true"
                            v-bind:precision="1">
                        </vue-numeric> /
                        <span class="text-success">
                          <vue-numeric
                              currency-symbol-position="suffix"
                              :currency="current_currency"
                              v-bind:value="result[status.key+'_amount']"
                              v-bind:read-only="true"
                              v-bind:precision="$get_precision()">
                        </vue-numeric>
                        </span>
                      </td>
                </tr>
            </tbody>
          </table>
        </div>

      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Ib",
  data: function () {
    return {
      filterQuery: {
        filterby: 'today'
      },
      response_results: null,
      data: null,
      current_currency:this.$root.current_currency,
    }
  },
  methods: {
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/reports/ib', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.response_results = resp.data.results;
            this.data = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.index()
  },
  computed:{
    deposit_status_with_key: function () {
      return this.$root.$data.global_settings.deposit_status_with_key;
    },
    bank_supports:function (){
      return this.$root.$data.global_settings.bank_supports;
    },
    results:function (){
      if( !this.response_results){
          return null
      }
      let response_results = this.response_results
      let results = []
      let deposit_status_with_key = this.deposit_status_with_key
      response_results.forEach(el=>{
        let item = el
        let total_transaction = el.total_transaction
        deposit_status_with_key.forEach(status=>{
          item[status.key+'_percent'] = (el[status.key]/total_transaction) *100
        })

        results.push(item)
      })
      return  results
    }
  }
}
</script>

<style scoped>

</style>