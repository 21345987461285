<template>
  <div v-if="auto_status" class="card">
    <div class="card-body">
      <h6>{{ $trans('Auto status') }}</h6>
      <hr>
      <div v-for="(user,key) in auto_status" :key="key" class="bank-item">
        <span class="name mr-auto">{{ user.name }}</span>
        <span class="status">
                    <span v-if="user.live == 1" class="mr-10 mt-0 badge badge-success">{{ $trans('Online') }}</span>
                    <span v-else-if="user.live == 0" class="mr-0 mt-0 badge badge-dark">{{ $trans('Offline') }}</span>
                    <span v-else class="">-</span>
                </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "AutoSatus",
  data:function (){
      return {
        auto_status:null
      }
  },
  methods:{
    get_auto_status: function () {
      axios({url: this.$root.$data.api_url + '/api/statistic/auto_status', params: [], method: 'GET'})
          .then(resp => {
            this.auto_status = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.get_auto_status()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );

    // Auto
    const channel = pusher.subscribe('user-channel');
    channel.bind('user-event', (e) => {
      if (e.action == 'updated' && this.auto_status) {
        var arr = this.auto_status;
        var tmp_arr = [];
        var current_id = e.user._id;
        arr.forEach(function (el, index) {
          if (current_id == el._id) {
            tmp_arr[index] = e.user;
          } else {
            tmp_arr[index] = el;
          }
        });
        this.auto_status = tmp_arr;
      }
    });
  }
}
</script>

<style scoped>

</style>