<template>
  <div class="form google2fa-form">
    <form class="login" @submit.prevent="VerifyGoogle2FA">
      <h6>{{$trans(' Please enter the OTP to continue login your account.')}}</h6>
      <hr>
      <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
        {{alert.message}}
      </div>
      <div class="form-group">
        <label>{{$trans('One time password')}}</label>
        <input class="form-control"  v-model="one_time_password" type="text"/>
        <slot v-if="errors && errors['one_time_password']">
          <div class="text-danger" v-for="error in errors['one_time_password']"
               v-bind:key="error">
            <small>{{ error }}</small>
          </div>
        </slot>
      </div>
      <div class="d-flex">
        <button class="btn btn-primary btn-block" type="submit">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Submit')}}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VerifyGoogle2FA",
  data:function (){
      return {
        process:false,
        errors:{},
        alert:null,
        one_time_password:''
      }
  },
  methods:{
    VerifyGoogle2FA:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data ={
          one_time_password:this.one_time_password
        }
        axios({url: this.$root.$data.api_url + '/api/security/verify_google2FA',data:data, method: 'POST'})
            .then(resp => {
              this.process = false;
              const error_code = resp.data.error_code;
              if (error_code == 0) {
                this.$router.push('/')
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }
    }
  }
}
</script>

<style scoped>

</style>