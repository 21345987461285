<template>
  <div>
    <div class="d-flex">
      <div>
        <button @click="sync_histories" style="font-size: 14px" class="btn btn-info btn-sm mr-3">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Sync')}}
        </button>
      </div>
      <input @change="historyFilter" v-model="filterQuery.keyword" :placeholder="$trans('Account, tranId, comment')" class="form-control w-250 mr-3 mb-3" type="text">
    </div>
    <slot v-if="histories && histories.data">
      <div class="table-responsive">
        <div class="list-info mb-3">
          <small>{{$trans('Results')}}: <strong>{{histories.data.length}}</strong> {{$trans('of')}} <strong>{{histories.total}}</strong></small>
        </div>
        <table v-if="transactions" class="table table-striped">
          <thead>
          <tr>
            <th>{{ $trans('Account') }}</th>
            <th>{{ $trans('ID') }}</th>
            <th>{{ $trans('Sender Number') }}</th>
            <th class="text-right nowrap">{{ $trans('Original Amount') }}</th>
            <th class="text-right nowrap">{{ $trans('Fee') }}</th>
            <th>{{ $trans('Comment') }}</th>
            <th>{{ $trans('Status') }}</th>
            <th class="nowrap">{{ $trans('Date') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(trans,index) in transactions" v-bind:key="index">
            <td>
              {{trans.username}}
              <slot v-if="trans.account"> - {{trans.account.displayname}}</slot>
            </td>
            <td>{{trans.transid}}</td>
            <td>
              <slot v-if="trans.item && trans.item.sender && trans.item.sender.phonenumber">
                {{trans.item.sender.phonenumber}}
              </slot>

            </td>
            <td class="text-right nowrap">
                <span class="text-danger" v-if="trans.sign == -1">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="-trans.amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
              <span class="text-success" v-if="trans.sign == 1">
                  +<vue-numeric
                  currency-symbol-position="suffix"
                  :currency="'VND'"
                  v-bind:value="trans.amount"
                  v-bind:read-only="true"
                  v-bind:precision="0">
                  </vue-numeric>
                </span>
            </td>
            <td class="text-right nowrap">
              <vue-numeric
                  currency-symbol-position="suffix"
                  :currency="'VND'"
                  v-bind:value="trans.userfeeamt"
                  v-bind:read-only="true"
                  v-bind:precision="0">
              </vue-numeric>
            </td>
            <td>
              {{trans.description}}
            </td>
            <td>
              <span v-if="trans.transstatus == 1" class="badge  badge-success badge-pill">{{$trans('Success')}}</span>
            </td>
            <td class="nowrap">{{trans.date}}</td>
            <td>
              <span v-if="trans.is_confirmed && trans.is_confirmed==1" class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Confirmed') }}</span>
              <span v-else-if="trans.is_confirmed && trans.is_confirmed==0" class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Unconfirmed') }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <paginate v-if="histories && histories.data && histories.last_page"
                :page-count="histories.last_page"
                :click-handler="paginate"
                :prev-text="$trans('Previous')"
                :next-text="$trans('Next')"
                :page-class="'page-item'"
                :prev-class="'page-item'"
                :next-class="'page-item'"
                :page-link-class="'page-link'"
                :prev-link-class="'page-link'"
                :next-link-class="'page-link'"
                :container-class="'pagination'">
      </paginate>
    </slot>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HistoryDb",
  data:function (){
      return {
        filterQuery:{
            page:1
        },
        histories:null,
        errors:{},
        alert:null,
        process:false
      }
  },
  methods:{
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    historyFilter:function (){
      this.filterQuery.page = 1
      this.index()
    },
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/zalo/get_history/db',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.histories = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    sync_histories:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/zalo/sync_histories', data:[],method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.index()
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.index()
  },
  computed:{
    transactions:function (){
      if (!this.histories){
        return []
      }
      if (!this.histories.data){
        return []
      }
      let tranList =[];
      let listT = this.histories.data
      listT.forEach(el=>{
        let item = el
        let dateTime = new Date(el.reqdate);
        let date = dateTime.getFullYear() +"-"+(dateTime.getMonth()+1) + '-'+dateTime.getDate() +" "+dateTime.getHours()+
            ":"+dateTime.getMinutes()+
            ":"+dateTime.getSeconds()
        item.date = date;
        tranList.push(item)
      })
      //tranList.reverse()
      return  tranList
    }
  }
}
</script>

<style scoped>

</style>