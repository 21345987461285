<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex report-header">
        <h4 class="mb-0 mr-auto">{{ $trans('IB Reports') }}</h4>
      </div>
      <hr>
      <div class="d-flex mb-3">
        <select v-model="filterQuery.filterby" class="form-control w-200 mr-3" @change="index">
          <option value="month">This Month</option>
          <option value="last_month">Last Month</option>
          <option value="select_month">Select Month</option>
        </select>
        <select v-if="filterQuery.filterby =='select_month'" v-model="filterQuery.month" class="form-control w-200 mr-3"
                @change="index">
          <option v-for="(month,i) in months" :key="i" :value="i">{{ month }}</option>
        </select>
        <download-csv v-if="json_data" :labels="labels" :data="json_data" :name="'ib_report.csv'">
          <button class="btn btn-primary">{{ $trans('Export') }}</button>
        </download-csv>
      </div>
      <slot v-if="report_data">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Date') }}</th>
              <th class="text-right">{{ $trans('Revenue') }}</th>
              <th class="text-right">{{ $trans('Profit') }}</th>
              <th class="text-right">{{ $trans('Total Transaction') }}</th>
              <th class="text-right">{{ $trans('Total Success Transaction') }}</th>
              <th class="text-right">{{ $trans('Transaction time is over') }}</th>
              <th class="text-right">{{ $trans('Less than 5 min') }}</th>
              <th class="text-right">{{ $trans('More than 5 min') }}</th>
              <th class="text-right">{{ $trans('AVG Time (Min)') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(report,i) in report_data" :key="i">
              <td>{{ report.date }}</td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="report.revenue"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="report.profit"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.total_transaction"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.total_success_transaction"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.total_timeout_transaction"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.total_transaction_is_less_than_5p"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.total_transaction_is_more_than_5p"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="''"
                    v-bind:value="report.avg_time*60"
                    v-bind:read-only="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th>{{ $trans('Total') }}:</th>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="report_total.revenue"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="report_total.profit"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.total_transaction"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.total_success_transaction"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.total_timeout_transaction"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.total_transaction_is_less_than_5p"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.total_transaction_is_more_than_5p"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="''"
                      v-bind:value="report_total.ib_avg_time_success *60"
                      v-bind:read-only="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </strong>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "IbReport",
  data: function () {
    return {
      filterQuery: {
        filterby: 'month',
        month: '01'
      },
      ib_statistic_data: null,
      months: {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
      },
      labels: {
        'date': 'Date',
        'revenue': 'Revenue',
        'profit': 'Profit',
        'total_transaction': 'Total transaction',
        'total_success_transaction': 'Total success transaction',
        'total_timeout_transaction': 'Transaction time is over',
        'total_transaction_is_less_than_5p': 'Less than 5 min',
        'total_transaction_is_more_than_5p': 'More than 5 min',
        'avg_time': 'AVG Time (Min)',
      }
    }
  },
  methods: {

    index: function () {
      axios({url: this.$root.$data.api_url + '/api/statistic/ib', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.ib_statistic_data = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    }

  },
  created() {
    this.index()
  },
  computed: {
    report_data:function (){
      if( !this.ib_statistic_data){
        return  null
      }
      let options = {  year: 'numeric', month: 'long', day: 'numeric' };
      let ib_statistic_data = this.ib_statistic_data.results
      let data = []
      ib_statistic_data.forEach((el=>{
        let item = el
        let date  = new Date(el.vAxisTitle);
        item.date = date.toLocaleDateString("en-US", options)

        data.push(item)
      }))
      return  data

    },
    report_total: function () {
      let data = {
        revenue: 0,
        profit: 0,
        total_success_transaction: 0,
        total_transaction: 0
      }
      if (this.ib_statistic_data) {
        let results = this.ib_statistic_data.results
        let revenue = 0
        let profit = 0
        let total_success_transaction = 0
        let total_transaction = 0
        let total_timeout_transaction = 0
        let total_transaction_is_less_than_5p = 0
        let total_transaction_is_more_than_5p = 0
        let ib_avg_time_success =0
        results.forEach(el => {
          revenue = revenue + el.revenue
          profit = profit + el.profit
          total_success_transaction = total_success_transaction + el.total_success_transaction
          total_transaction = total_transaction + el.total_transaction
          total_timeout_transaction = total_timeout_transaction + el.total_timeout_transaction
          total_transaction_is_less_than_5p = total_transaction_is_less_than_5p + el.total_transaction_is_less_than_5p
          total_transaction_is_more_than_5p = total_transaction_is_more_than_5p + el.total_transaction_is_more_than_5p
          ib_avg_time_success = ib_avg_time_success + el.avg_time
        })
        data = {
          revenue: revenue,
          profit: profit,
          total_success_transaction: total_success_transaction,
          total_transaction: total_transaction,
          total_timeout_transaction: total_timeout_transaction,
          total_transaction_is_less_than_5p: total_transaction_is_less_than_5p,
          total_transaction_is_more_than_5p: total_transaction_is_more_than_5p,
          ib_avg_time_success:(ib_avg_time_success/results.length)
        }
      }
      return data
    },
    json_data: function () {
      if (!this.report_data) {
        return null
      }
      let data = []
      let results = this.report_data
      results.forEach(el => {
        let item = {
          date: el.date,
          revenue: el.revenue,
          profit: el.profit,
          total_transaction: el.total_transaction,
          total_success_transaction: el.total_success_transaction,
          total_timeout_transaction: el.total_timeout_transaction,
          total_transaction_is_less_than_5p: el.total_transaction_is_less_than_5p,
          total_transaction_is_more_than_5p: el.total_transaction_is_more_than_5p,
          avg_time: (el.avg_time *60).toFixed(2),
        }
        data.push(item)
      })
      let total_item ={
        date: 'Total',
        revenue: this.report_total.revenue,
        profit: this.report_total.profit,
        total_transaction: this.report_total.total_transaction,
        total_success_transaction: this.report_total.total_success_transaction,
        total_timeout_transaction: this.report_total.total_timeout_transaction,
        total_transaction_is_less_than_5p: this.report_total.total_transaction_is_less_than_5p,
        total_transaction_is_more_than_5p: this.report_total.total_transaction_is_more_than_5p,
        avg_time: (this.report_total.ib_avg_time_success *60).toFixed(2),
      }
      data.push(total_item)
      return data
    },
  }
}
</script>

<style scoped>

</style>