<template>
  <div class="deposit card">
    <div class="card-header">
      <h2>{{ $trans('Deposit') }}</h2>
    </div>
    <div class="d-flex mb-3">
      <input @change="filter" v-model="filterQuery.keyword" :placeholder="$trans('Ref number or Order ID')" class="form-control w-150 mr-3" type="text">
      <Select2 @change="filter" class="mr-3" v-model="filterQuery.user_id" :settings="settings"/>
      <select @change="filter" v-model="filterQuery.method" class="form-control w-120 mr-3">
        <option value="all">{{ $trans('All method') }}</option>
        <option value="IB">{{ $trans('Internet banking') }}</option>
        <option value="BANK_TRANSFER">{{ $trans('Bank transfer') }}</option>
        <option value="MOMO">{{ $trans('Momo wallet') }}</option>
        <option value="QRCODE">{{ $trans('Qrcode') }}</option>
        <option value="ZALO">{{ $trans('Zalo') }}</option>
      </select>
      <select @change="filter" v-model="filterQuery.bank" class="form-control w-120 mr-3">
        <option value="all">{{ $trans('All bank') }}</option>
        <option v-for="(bank,key) in bank_supports" v-bind:key="key" :value="key">{{ bank }}</option>
      </select>
      <select @change="filter" v-model="filterQuery.status" class="form-control w-120 mr-3">
        <option value="all">{{ $trans('All status') }}</option>
        <option v-for="(status,key) in deposit_status" v-bind:key="key" :value="key">{{ status }}</option>
      </select>
      <select @change="filter" v-model="filterQuery.filterby" class="form-control w-120 mr-3">
        <option value="all">{{ $trans('All date') }}</option>
        <option value="today">{{ $trans('Today') }}</option>
        <option value="yesterday">{{ $trans('Yesterday') }}</option>
        <option value="month">{{ $trans('This month') }}</option>
        <option value="last_month">{{ $trans('Last month') }}</option>
      </select>
      <button @click="do_export" class="btn btn-primary mr-3">
        <i v-if="process" class="fa fa-spinner fa-spin"></i>
        {{$trans('Export')}}
      </button>
      <download-csv v-if="json_data && json_data.length" :labels="labels" :data="json_data" :name="'transactions.csv'">
        <button class="btn btn-primary">{{ $trans('Download') }}</button>
      </download-csv>
    </div>
    <div class="card-body">
      <slot v-if="transactions && transactions.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ transactions.data.length }}</strong> {{ $trans('of') }} <strong>{{ transactions.total }}</strong></small>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Ref Number') }}</th>
              <th>{{ $trans('Method') }}</th>
              <th>{{ $trans('Order ID') }}</th>
              <th>{{ $trans('Client') }}</th>
              <slot v-if="show_account_info">
                <th>{{ $trans('Deposit account') }}</th>
                <th>{{ $trans('Account name') }}</th>
                <th>{{ $trans('Account number') }}</th>
                <th>{{ $trans('Sender Account No') }}</th>
              </slot>
              <slot v-else>
                  <th>{{ $trans('Wallet Address') }}</th>
              </slot>

              <th class="text-right">{{ $trans('Amount') }}</th>
              <th>{{ $trans('Date') }}</th>
              <th>{{ $trans('Status') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transaction,index) in transactions.data" v-bind:key="index">
              <td>{{ transaction.ref_number }}</td>
              <td>
                <span v-if="transaction.method=='IB'" class="mr-1 mt-1 badge badge-info">{{ $trans('IB') }}</span>
                <span v-if="transaction.method=='BANK_TRANSFER'" class="mr-1 mt-1 badge badge-primary">{{ $trans('BT') }}</span>
                <span v-if="transaction.method=='MOMO'" class="mr-1 mt-1 badge badge-success">{{ $trans('MOMO') }}</span>
                <span v-if="transaction.method=='ZALO'" class="mr-1 mt-1 badge badge-success">{{ $trans('Zalo') }}</span>
                <span v-if="transaction.method=='QRCODE'" class="mr-1 mt-1 badge badge-success">{{ $trans('Qrcode') }}</span>
                <span v-if="transaction.method=='CRYPTO'" class="mr-1 mt-1 badge badge-dark">{{ $trans('CRYPTO') }}</span>
              </td>
              <td>{{ transaction.order_id }}</td>
              <td>{{ transaction.user.name }}</td>
              <slot v-if="show_account_info">
                <td>
                  <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.name }}</slot>
                </td>
                <td>
                  <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_name }}</slot>
                </td>
                <td>
                  <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_number }}</slot>
                </td>
                <td>
                  <slot v-if="transaction.send_account_number">
                    {{ transaction.send_account_number }}
                  </slot>
                  <slot v-else>-</slot>
                </td>
              </slot>
              <slot v-else>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.wallet_address }}</slot>
                  </td>
              </slot>

              <td class="text-right text-success">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="current_currency"
                    v-bind:value="transaction.amount"
                    v-bind:read-only="true"
                    v-bind:precision="$get_precision()">
                </vue-numeric>
              </td>
              <td>{{ transaction.created_at }}</td>
              <td>
                <span v-if="transaction.status==1"
                      class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                <span v-if="transaction.status==2"
                      class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Pending') }}</span>
                <span v-if="transaction.status==3"
                      class="mr-1 mt-1 badge badge-info badge-pill">{{ $trans('Processing') }}</span>
                <span v-if="transaction.status==0"
                      class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                <span v-if="transaction.status==-1"
                      class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                <span v-if="transaction.status<-1"
                      class="mr-1 mt-1 badge badge-danger badge-pill">{{ deposit_status[transaction.status] }}</span>
              </td>
              <td class="text-right">
                <a @click="detail(transaction._id)" href="javascript:void(0)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                       class="feather feather-external-link">
                    <path data-v-52ca7221="" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline data-v-52ca7221="" points="15 3 21 3 21 9"></polyline>
                    <line data-v-52ca7221="" x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="transactions && transactions.data && transactions.last_page"
                  :page-count="transactions.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
    <modal v-if="showDetail && $can('view-deposit') && transaction" v-bind:width="'80%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Transaction detail') }}
          <button @click="showDetail = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <nav class="mb-3">
          <div class="nav nav-tabs" id="nav-tab">
            <a class="nav-item nav-link" @click="tabActive = 'detail'" v-bind:class="{'active':tabActive=='detail'}"
               href="javascript:void(0)">{{ $trans('Detail') }}</a>
            <a class="nav-item nav-link" @click="tabActive = 'logs'" v-bind:class="{'active':tabActive=='logs'}"
               href="javascript:void(0)">{{ $trans('Logs') }}</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div v-if="tabActive == 'detail'" class="tab-pane active">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th>{{ $trans('Ref Number') }}</th>
                <td>{{ transaction.ref_number }}</td>
              </tr>
              <tr>
                <th>{{ $trans('Method') }}</th>
                <td>
                  <span v-if="transaction.method=='IB'"
                        class="mr-1 mt-1 badge badge-info badge-pill">{{ $trans('Internet banking') }}</span>
                  <span v-if="transaction.method=='BANK_TRANSFER'"
                        class="mr-1 mt-1 badge badge-primary badge-pill">{{ $trans('Bank transfer') }}</span>
                  <span v-if="transaction.method=='MOMO'"
                        class="mr-1 mt-1 badge badge-dark badge-pill">{{ $trans('MOMO wallet') }}</span>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Order ID') }}</th>
                <td>{{ transaction.order_id }}</td>

              </tr>
              <slot v-if="show_account_info">
                <tr>
                  <th>{{ $trans('Deposit account') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.name }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Account name') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_name }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Account number') }}</th>
                  <td>
                    <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.account_number }}</slot>
                  </td>

                </tr>
                <tr>
                  <th>{{ $trans('Sender Account No') }}</th>
                  <td>
                    <slot v-if="transaction.send_account_number">
                      {{ transaction.send_account_number }}
                    </slot>
                    <slot v-else>-</slot>
                  </td>
                </tr>
              </slot>
              <slot v-else>
                  <tr>
                    <th>{{$trans('Wallet Address')}}</th>
                    <td>
                      <slot v-if="transaction.deposit_account">{{ transaction.deposit_account.wallet_address }}</slot>
                    </td>
                  </tr>
              </slot>
              <tr>
                <th>{{ $trans('Trading Code') }}</th>
                <td>
                  <slot v-if="transaction.trading_code">
                    {{ transaction.trading_code }}
                  </slot>
                  <slot v-else>-</slot>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Amount') }}</th>
                <td class=" text-success">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="current_currency"
                      v-bind:value="transaction.amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Date') }}</th>
                <td>{{ transaction.created_at }}</td>

              </tr>
              <tr>
                <th>{{ $trans('Status') }}</th>
                <td>
                  <span v-if="transaction.status==1"
                        class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                  <span v-if="transaction.status==2"
                        class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Pending') }}</span>
                  <span v-if="transaction.status==3"
                        class="mr-1 mt-1 badge badge-info badge-pill">{{ $trans('Processing') }}</span>
                  <span v-if="transaction.status==0"
                        class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                  <span v-if="transaction.status==-1"
                        class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                  <span v-if="transaction.status<-1"
                        class="mr-1 mt-1 badge badge-danger badge-pill">{{ deposit_status[transaction.status] }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="$can('confirm-deposit')" class="form-actions">
              <slot v-if="transaction.status <=0 && transaction.with_account == 'no'">
                <div class="alert alert-info">
                  {{ $trans('In case the bank has received the money but the system does not update. Please update manually ') }}
                </div>
                <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
                  {{ alert.message }}
                </div>
                <div class="form-group">
                  <label>{{ $trans('Reason') }}(*)</label>
                  <input v-model="reason" class="form-control" type="text">
                  <slot v-if="errors && errors['reason']">
                    <div class="text-danger" v-for="error in errors['reason']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <button @click="confirm_transaction(transaction._id)" class="btn btn-primary">
                  <i v-if="process" class="fa fa-spinner fa-spin"></i>
                  {{ $trans('Confirm Transaction') }}
                </button>
              </slot>
            </div>
          </div>
          <div v-if="tabActive == 'logs'" class="tab-pane active">
            <table v-if="$can('view-deposit-logs')" class="table table-striped">
              <tbody>
              <tr v-if="transaction.logs && transaction.logs.length">
                <th style="vertical-align: top">{{ $trans('Logs') }}</th>
                <td>
                  <span class="d-block" v-for="(log,k) in transaction.logs" v-bind:key="k">{{ log }}</span>
                </td>
              </tr>
              <tr v-if="transaction.auto_logs && transaction.auto_logs.length && decode_auto_logs">
                <th style="vertical-align: top">{{ $trans('Auto Logs') }}</th>
                <td>
                  <textarea style="width: 100%" class="" disabled v-model="decode_auto_logs"></textarea>
                </td>
              </tr>
              <tr>
                <th style="vertical-align: top">{{ $trans('Callback') }}</th>
                <td>
                  <slot v-if="transaction.callback">
                    <span class="d-block"><strong>Status:</strong> {{ transaction.callback.status }}</span>
                    <span class="d-block"><strong>Url:</strong> {{ transaction.callback.url }}</span>
                    <span class="d-block"><strong>Http Code:</strong> {{ transaction.callback.http_code }}</span>
                    <span class="d-block"><strong>Time:</strong> {{ transaction.callback.time }}</span>
                    <span class="d-block">
                        <strong>{{$trans('Input')}}:</strong>
<pre>
{{ transaction.callback.input }}
</pre>
                      </span>
                    <span class="d-block">
                        <strong>{{$trans('Response')}}:</strong>
<pre>
{{ transaction.callback.response }}
</pre>
                      </span>
                  </slot>
                  <span class="d-block">
                      <button @click="re_callback(transaction._id)" class="btn btn-primary">
                        <i v-if="process" class="fa fa-spinner fa-spin"></i>
                        {{$trans('Re-Callback')}}
                      </button>
                  </span>
                </td>
              </tr>
              <tr v-if="transaction.screenshots && transaction.screenshots.length">
                <th style="vertical-align: top">{{ $trans('Screenshots') }}</th>
                <td>
                  <gallery :images="transaction.screenshots" :index="gallery_index"
                           @close="gallery_index = null"></gallery>
                  <span class="image text-success" @click="gallery_index = 1">{{ $trans(' View Screenshots') }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-else class="alert alert-warning">{{ $trans('You do not have permission') }}</div>
          </div>
        </div>

      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "Deposit",
  data: function () {
    return {
      transactions: null,
      filterQuery: {
        page: 1,
        keyword: '',
        method: 'all',
        status: 'all',
        bank: 'all',
        filterby: 'month',
        user_id:''
      },
      current_currency: this.$root.current_currency,
      transaction: null,
      showDetail: false,
      tabActive: 'detail',
      gallery_index: null,
      reason: '',
      errors: {},
      alert: null,
      process: false,
      settings: {
        ajax: {
          url: this.$root.$data.api_url + '/api/ajax/search/users',
          dataType: 'json',
          processResults: function (data) {
            let users = data;
            let results = []
            users.forEach((el) => {
              let user = {
                id: el._id,
                text: el.username
              }
              results.push(user)
            })
            return {results: results}
          },
        },
        multiple: false,
        placeholder:'Select a client',
        width:120,
        minimumInputLength:3
      },
      export_transactions:null,
      labels:{
        'ref_number':'Ref Number',
        'method':'Method',
        'order_id':'Order ID',
        'client':'Client',
        'deposit_account':'Deposit account',
        'account_name':'Account name',
        'account_number':'Account number',
        'send_account_number':'Sender Account No',
        'amount':'Amount',
        'created_at':'Date',
        'status':'Status'
      }
    }
  },
  methods: {
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/deposit/transactions', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.transactions = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    do_export:function ( ){
      if (this.process == false) {
        this.process = true;
        this.export_transactions = null;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/deposit/export', params: this.filterQuery, method: 'GET'})
            .then(resp => {
              this.export_transactions = resp.data;
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    detail: function (id) {
      this.showDetail = true
      this.tabActive = 'detail'
      axios({url: this.$root.$data.api_url + '/api/deposit/transactions/' + id, params: {}, method: 'GET'})
          .then(resp => {
            this.transaction = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    confirm_transaction: function (id) {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          reason: this.reason
        }
        axios({url: this.$root.$data.api_url + '/api/deposit/transactions/confirm/' + id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.detail(id);
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    re_callback:function (id){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          reason: this.reason
        }
        axios({url: this.$root.$data.api_url + '/api/deposit/transactions/re_callback/' + id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.detail(id);
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('deposit-channel');
    channel.bind('deposit-event', (e) => {
      if (this.current_currency == e.currency) {
        if (this.transactions && this.transactions.data) {
          if (e.action == 'created') {
            this.transactions.data.unshift(e.transaction);
          } else {
            var arr = this.transactions.data;
            var tmp_arr = [];
            var current_id = e.transaction._id;
            arr.forEach(function (el, index) {
              if (current_id == el._id) {
                tmp_arr[index] = e.transaction;
              } else {
                tmp_arr[index] = el;
              }
            });
            this.transactions.data = tmp_arr;
          }
        }
      }

    });
  },
  computed: {
    deposit_status: function () {
      return this.$root.$data.global_settings.deposit_status;
    },
    bank_supports: function () {
      return this.$root.$data.global_settings.bank_supports;
    },
    show_account_info:function (){
      let arr =['VND','THB','IDR'];
      if( arr.includes(this.current_currency)){
        return  true
      }
      return false;
    },
    decode_auto_logs:function (){
        if (!this.transaction){
            return '';
        }
        if( !this.transaction.auto_logs){
            return  ''
        }
        let auto_logs = this.transaction.auto_logs
        let logs=''
        auto_logs.forEach((log)=>{
           // let str = atob(log)
            let str = decodeURIComponent(escape(window.atob( log )))
            logs = logs+str
        });
        return logs
    },
    json_data:function (){
      if( !this.export_transactions){
        return null
      }
      let export_transactions = this.export_transactions
      let data =[]
      export_transactions.forEach((el)=>{
        let item = {
          ref_number:el.ref_number,
          method:el.method,
          order_id:el.order_id,
          client:el.user.name,
          deposit_account:el.deposit_account.name,
          account_name:el.deposit_account.account_name,
          account_number:el.deposit_account.account_number,
          send_account_number:el.send_account_number,
          amount:el.amount,
          created_at:el.created_at,
          status:this.deposit_status[el.status]
        }
        data.push(item)
      })
      return data
    }
  }
}
</script>

<style scoped>

</style>