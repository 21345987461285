<template>
  <div class="card">
      <div class="card-body">
        <h6>{{$trans('Two Factor Authentication')}}</h6>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <slot v-if="user">
          <slot v-if="user.google2fa_enable == 1">
            <div class="alert alert-success">
              2FA is Currently <strong>Enabled</strong> for your account.
            </div>
            <p>If you are looking to disable Two Factor Authentication. Please confirm your password and Click Disable 2FA Button.</p>
            <div class="form-group">
              <label>{{$trans('Authenticator Code')}}</label>
              <input v-model="one_time_password" class="form-control" type="text">
              <slot v-if="errors && errors['one_time_password']">
                <div class="text-danger" v-for="error in errors['one_time_password']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <button @click="disable_Google2FA" type="submit" class="btn btn-primary ">
              <i v-if="process" class="fa fa-spinner fa-spin"></i>
              {{$trans('Disable 2FA')}}
            </button>
          </slot>
          <slot v-else>
            <slot v-if="confirm_form">
              <p>
                You will need to install the <strong> Google Authenticator </strong> mobile App, scan and save the generated QR code below
              </p>
              <p>
                Install Google Authenticator:
                <a  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank" class="badge badge-info mr-2">
                  <i class="fab fa-android mr-1"></i> Android
                </a>
                <a href="https://apps.apple.com/ph/app/google-authenticator/id388497605" target="_blank" class="badge badge-info">
                  <i class="fab fa-app-store-ios mr-1"></i> iOS
                </a>
              </p>
              <p><strong>1. Scan this QR code with your Google Authenticator App:</strong></p>
              <img :src="qrcode_image" alt="">
              <p><strong>2.Enter the pin the code to Enable 2FA</strong></p>
              <div class="form-group">
                <label>{{$trans('Authenticator Code')}}</label>
                <input v-model="one_time_password" class="form-control" type="text">
                <slot v-if="errors && errors['one_time_password']">
                  <div class="text-danger" v-for="error in errors['one_time_password']"
                       v-bind:key="error">
                    <small>{{ error }}</small>
                  </div>
                </slot>
              </div>
              <button @click="confirm_Google2FA" class="btn btn-primary">
                {{$trans('Enable 2FA')}}
              </button>
            </slot>
            <slot v-else>
              <p>Two factor authentication (2FA) strengthens access security by requiring two methods (also referred to as factors) to verify your identity. Two factor authentication protects against phishing, social engineering and password brute force attacks and secures your logins from attackers exploiting weak or stolen credentials.</p>
              <p>To Enable Two Factor Authentication on your Account, you need to do following steps</p>
              <strong>
                <ol style="padding-left: 15px;">
                  <li>Click on Generate Secret Button , To Generate a Unique secret QR code for your profile</li>
                  <li>Verify the OTP from Google Authenticator Mobile App</li>
                </ol>
              </strong>
              <button @click="enbale_Google2FA" class="btn btn-primary">
                <i v-if="process" class="fa fa-spinner fa-spin"></i>
                {{$trans('Generate Secret Key to Enable 2FA')}}
              </button>
            </slot>
          </slot>
        </slot>
      </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Google2FA",
  data:function (){
      return {
        user:null,
        process:false,
        errors:{},
        alert:null,
        qrcode_image:null,
        confirm_form:false,
        one_time_password:''
      }
  },
  methods:{
    get_info: function () {
      axios({url: this.$root.$data.api_url + '/api/profile', method: 'GET'})
          .then(resp => {
            this.user = resp.data.user;
          })
          .catch(err => {
            console.log(err)
          })
    },
    enbale_Google2FA:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/security/enable_google2FA',data:[], method: 'POST'})
            .then(resp => {
              this.process = false;
              const error_code = resp.data.error_code;

              if (error_code == 0) {
                this.confirm_form = true
                this.qrcode_image = resp.data.qrcode_image
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }
    },
    confirm_Google2FA:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data ={
          one_time_password:this.one_time_password
        }
        axios({url: this.$root.$data.api_url + '/api/security/confirm_google2FA',data:data, method: 'POST'})
            .then(resp => {
              this.process = false;
              const error_code = resp.data.error_code;
              if (error_code == 0) {
                this.get_info()
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }
    },
    disable_Google2FA:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data ={
          one_time_password:this.one_time_password
        }
        axios({url: this.$root.$data.api_url + '/api/security/disable_google2FA',data:data, method: 'POST'})
            .then(resp => {
              this.process = false;
              const error_code = resp.data.error_code;
              if (error_code == 0) {
                this.get_info()
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
            })
            .catch(err => {
              this.process = false;
              console.log(err)
            })
      }
    }
  },
  created() {
    this.get_info()
  }
}
</script>

<style scoped>

</style>