<template>
  <div>
    <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
      {{alert.message}}
    </div>
    <div class="form-transfer">
      <div class="form-group">
        <label>{{$trans('Type')}}</label>
        <select v-model="transferFields.type" class="form-control">
          <option value="account">{{ $trans('Zalo account') }}</option>
          <option value="bank">{{ $trans('To Bank') }}</option>
        </select>
        <slot v-if="errors && errors['type']">
          <div class="text-danger" v-for="error in errors['type']"
               v-bind:key="error">
            <small>{{ error }}</small>
          </div>
        </slot>
      </div>
      <div v-if="transferFields.type == 'bank' && feeinfo" class="form-group">
          <div class="alert alert-info">
              <ul style="padding-left: 20px; margin: 0">
                  <li v-for="(text,i) in feeinfo" :key="i" v-html="text"></li>
              </ul>
          </div>
      </div>
      <div class="form-group">
        <label>{{$trans('Amount')}}</label>
        <vue-numeric class="form-control"
                     currency-symbol-position="suffix"
                     :currency="'VND'"
                     v-model="transferFields.amount"
                     v-bind:read-only="false"
                     v-bind:precision="0">
        </vue-numeric>
        <slot v-if="errors && errors['amount']">
          <div class="text-danger" v-for="error in errors['amount']"
               v-bind:key="error">
            <small>{{ error }}</small>
          </div>
        </slot>
      </div>
      <slot v-if="transferFields.type == 'account'">
        <div class="form-group">
          <label>{{$trans('To account')}}</label>
          <input v-model="transferFields.phone" :placeholder="$trans('Ex:0987654321')" class="form-control" type="text">
          <slot v-if="errors && errors['phone']">
            <div class="text-danger" v-for="error in errors['phone']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
      </slot>
      <slot v-if="transferFields.type =='bank'">

        <div class="form-group">
          <label>{{$trans('Bank')}}</label>
          <Select2 @change="bank_change" v-model="transferFields.bank" :options="list_bank_options"  />
          <slot v-if="errors && errors['bank']">
            <div class="text-danger" v-for="error in errors['bank']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Account name')}}</label>
          <input v-model="transferFields.account_name" class="form-control" type="text">
          <slot v-if="errors && errors['account_name']">
            <div class="text-danger" v-for="error in errors['account_name']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Account number')}}</label>
          <input v-model="transferFields.account_number" class="form-control" type="text">
          <slot v-if="errors && errors['account_number']">
            <div class="text-danger" v-for="error in errors['account_number']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
      </slot>
      <div class="form-group">
        <label>{{$trans('Comment')}}</label>
        <textarea v-model="transferFields.comment" class="form-control"></textarea>
      </div>
      <button v-if="transferFields.type=='account'" @click="transfer" class="btn btn-primary">
        <i v-if="process" class="fa fa-spinner fa-spin"></i>
        {{$trans('Transfer to account')}}
      </button>
      <button v-if="transferFields.type=='bank'" @click="transfer_to_bank" class="btn btn-primary">
        <i v-if="process" class="fa fa-spinner fa-spin"></i>
        {{$trans('Transfer to bank')}}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Transfer",
  props:{
    account_id: String,
  },
  data:function (){
      return {
        transferFields:{
          amount:0,
          phone:'',
          comment:'',
          type:'account',
          account_name:'',
          account_number:''
        },
        process:false,
        alert:null,
        errors:{},
        list_transfer_banks:null,
        bank_selected:null
      }
  },
  methods:{
    transfer:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.transferFields
        data.bank_selected = this.bank_selected
        axios({url: this.$root.$data.api_url + '/api/zalopay/transfer/'+this._props.account_id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.transferFields = {
                  type:'wallet',
                  amount:0,
                  wallet:'',
                  comment:''
                }
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    get_transfer_banks:function (){
      axios({
        url: this.$root.$data.api_url + '/api/zalopay/transfer_banks/' + this._props.account_id,
        params: this.filterQuery,
        method: 'GET'
      })
          .then(resp => {
            this.list_transfer_banks = resp.data.data.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    bank_change:function (){
      let supportedBanks = this.supportedBanks
      supportedBanks.forEach(el=>{
        if(el.bankcode == this.transferFields.bank ){
          this.bank_selected = el
        }
      })
    },
    transfer_to_bank:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.transferFields
        data.bank_selected = this.bank_selected
        data.limit = this.limit
        axios({url: this.$root.$data.api_url + '/api/zalopay/transfer_bank/'+this._props.account_id, data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.transferFields = {
                  type:'wallet',
                  amount:0,
                  wallet:'',
                  comment:''
                }
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    this.get_transfer_banks()
  },
  computed:{
    supportedBanks:function (){
        if( !this.list_transfer_banks){
            return null
        }
        return  this.list_transfer_banks.supportedBanks
    },
    feeinfo:function (){
      if( !this.list_transfer_banks){
        return[]
      }
      return  this.list_transfer_banks.feeinfo
    },
    limit:function (){
      if( !this.list_transfer_banks){
        return[]
      }
      return  this.list_transfer_banks.limit
    },
    list_bank_options:function (){
      if (!this.supportedBanks){
        return []
      }

      let list_bank_options =[]
      let list_bank = this.supportedBanks
      list_bank.forEach(el=>{
        let item = {
          id:el.bankcode,
          text:el.bankcode+' - '+el.fullname
        }
        list_bank_options.push(item)
      })
      return  list_bank_options
    }
  }
}
</script>

<style scoped>

</style>