<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h2 class="mr-auto">{{$trans('Thanh Toán')}}</h2>
      </div>
      <div class="card-body">
        <div class="row">
            <div class="col-sm-12">
<pre v-if="alert">
{{alert}}
</pre>
              <div class="form-group">
                <label>Method</label>
                <select v-model="ib_fields.method" class="form-control">
                  <option value="IB">IB</option>
                  <option value="BANK_TRANSFER">BANK TRANSFER</option>
                  <option value="MOMO">MOMO</option>
                  <option value="ZALO">ZALO</option>
                </select>
              </div>
              <div class="form-group">
                <label>Amount</label>
                <input class="form-control" v-model="ib_fields.amount" type="text">
              </div>
              <div v-if="ib_fields.method =='IB' || ib_fields.method =='BANK_TRANSFER'" class="form-group">
                <label>Bank</label>
                <select v-model="ib_fields.bank" class="form-control">
                  <option v-for="(bank,index) in bank_supports" :value="index" :key="index">{{bank}}</option>
                </select>
              </div>
              <button @click="payment_ib" class="btn btn-primary">
                <i v-if="process" class="fa fa-spinner fa-spin"></i>
                {{$trans('Payment')}}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
name: "ThanhToan",
  data:function (){
    return {
      ib_fields:{
          amount:10000,
          bank:'',
          return_url:'https://www.google.com/',
          method:'IB'
      },
      process:false,
      errors:{},
      alert:null
    }
  },
  methods:{
    payment_ib:function (){
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/thanhtoan', data: this.ib_fields, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                let url =  resp.data.data.url
                window.open(url, '_blank').focus()
              } else {
                this.alert = resp.data.data
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    }
  },
  created() {
    
  },
  computed:{
    bank_supports:function (){
      return this.$root.$data.global_settings.bank_supports;
    },
  }
}
</script>

<style scoped>

</style>