<template>
  <div class="card">
    <div class="card-header d-flex">
      <h2 class="mr-auto ">{{$trans('Zalopay')}}</h2>
      <button  @click="showHistory = true" class="btn btn-info btn-sm mr-3">
        {{$trans('View Histories')}}
      </button>
      <button v-if="$can('add-zalopay')" @click="addForm = true" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line><line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line></svg>
        {{$trans('Add new')}}
      </button>
    </div>
    <div class="card-body">
      <slot v-if="zalo_accounts && zalo_accounts.data">
        <div class="table-responsive">
          <div class="list-info mb-3 d-flex">
            <div class="mr-auto">
              <span>{{$trans('Results')}}: <strong>{{zalo_accounts.data.length}}</strong> {{$trans('of')}} <strong>{{zalo_accounts.total}}</strong></span>
            </div>
            <div class="total-balance">
              <strong>{{ $trans('Total balance') }}:
                <span class="text-success">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="zalo_total_balance"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
              </strong>
            </div>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{$trans('Username')}}</th>
              <th>{{$trans('Name')}}</th>
              <th class="text-right">{{$trans('Balance')}}</th>
              <th class="text-right">{{$trans('Deposit Limit (per month)')}}</th>
              <th class="text-right">{{$trans('Deposit of Month')}}</th>
              <th>{{$trans('Locked')}}</th>
              <th>{{$trans('Deposit Status')}}</th>
              <th>{{$trans('Account Status')}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(account,index) in list_zalo_accounts" v-bind:key="index">
              <td>{{account.username}}</td>
              <td>{{account.displayname}}</td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.balance"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.deposit_limit"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.total_deposit_of_month"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>

              <td>
                <span v-if="account.islocked == true" class="badge  badge- badge-pill">{{$trans('Yes')}}</span>
                <span v-if="account.islocked == false" class="badge badge-primary badge-pill">{{$trans('No')}}</span>
              </td>
              <td>
                <span v-if="account.deposit_status ==1" class="badge badge-primary badge-pill">{{$trans('Active')}}</span>
                <span v-if="account.deposit_status == 0" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
              </td>
              <td>
                <span v-if="account.status ==1" class="badge badge-primary badge-pill">{{$trans('Logged')}}</span>
                <span v-if="account.status == 0" class="badge badge-warning badge-pill">{{$trans('Logged out')}}</span>
                <span v-if="account.status == -1" class="badge badge-warning badge-pill">{{$trans('Inactive')}}</span>
              </td>
              <td class="text-right">
                <b-dropdown
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :text="$trans('Actions')"
                    class="m-2"
                    size="sm"
                >
                  <slot>
                    <b-dropdown-item  @click="detail(account)" href="#">{{$trans('Detail')}}</b-dropdown-item>
                    <b-dropdown-item  v-if="$can('edit-zalopay')" @click="edit(account)" href="#">{{$trans('Edit')}}</b-dropdown-item>
                    <b-dropdown-item  v-if="$can('delete-zalopay')" @click="destroy(account)" href="#"><span class="text-danger">{{$trans('Delete')}}</span></b-dropdown-item>
                  </slot>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="zalo_accounts && zalo_accounts.data && zalo_accounts.last_page"
                  :page-count="zalo_accounts.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
    <modal v-if="addForm && $can('add-momo-wallet')" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Add account')}}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Username')}} (*)</label>
          <input v-model="addFields.username" class="form-control" type="text">
          <slot v-if="errors && errors['username']">
            <div class="text-danger" v-for="error in errors['username']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Password')}} (*)</label>
          <input v-model="addFields.password" class="form-control" type="text">
          <slot v-if="errors && errors['password']">
            <div class="text-danger" v-for="error in errors['password']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Deposit Limit')}} (*)</label>
          <vue-numeric class="form-control"
                       v-bind:currency-symbol-position="'suffix'"
                       v-bind:currency="'VND'"
                       v-model="addFields.deposit_limit"
                       v-bind:read-only="false"
                       v-bind:precision="$get_precision()">
          </vue-numeric>
          <slot v-if="errors && errors['deposit_limit']">
            <div class="text-danger" v-for="error in errors['deposit_limit']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Deposit Status')}}</label>
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="add_deposit_status" type="checkbox" v-model="addFields.deposit_status">
            <label class="form-check-label" for="add_deposit_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['deposit_status']">
            <div class="text-danger" v-for="error in errors['deposit_status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="store" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Add')}}
        </button>
      </slot>
    </modal>
    <modal v-if="editForm && $can('edit-momo-wallet')" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Edit account')}}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <small>{{$trans('Fields Marked with (*) are required')}}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{alert.message}}
        </div>
        <div class="form-group">
          <label>{{$trans('Username')}} (*)</label>
          <input disabled v-model="updateFields.username" class="form-control" type="text">
          <slot v-if="errors && errors['username']">
            <div class="text-danger" v-for="error in errors['username']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Password')}} (*)</label>
          <input v-model="updateFields.password" class="form-control" type="text">
          <slot v-if="errors && errors['password']">
            <div class="text-danger" v-for="error in errors['password']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Deposit Limit')}} (*)</label>
          <vue-numeric class="form-control"
                       v-bind:currency-symbol-position="'suffix'"
                       v-bind:currency="'VND'"
                       v-model="updateFields.deposit_limit"
                       v-bind:read-only="false"
                       v-bind:precision="$get_precision()">
          </vue-numeric>
          <slot v-if="errors && errors['deposit_limit']">
            <div class="text-danger" v-for="error in errors['deposit_limit']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{$trans('Deposit Status')}}</label>
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="update_deposit_status" type="checkbox" v-model="updateFields.deposit_status">
            <label class="form-check-label" for="update_deposit_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['deposit_status']">
            <div class="text-danger" v-for="error in errors['deposit_status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{$trans('Update')}}
        </button>
      </slot>
    </modal>
    <modal v-if="showDetail && account" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Account Detail')}}
          <button @click="showDetail = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <hr>
        <div v-if="account.status != -1" class="alert alert-info">
          <div class="d-flex zalo-info">
              <div class="avatar mr-3">
                <img :src="account.avatar" alt="">
              </div>
              <div class="info">
                <strong>{{$trans('Name')}}:</strong> {{account.displayname}}<br>
                <strong>{{$trans('Phone')}}:</strong> {{account.username}}<br>
                <strong>{{$trans('Balance')}}: </strong>
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.balance"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </div>
          </div>
        </div>
        <slot v-if="account.status == -1">
          <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
            {{alert.message}}
          </div>
          <div class="form-group">
            <label>{{$trans('OTP')}} (*)</label>
            <input v-model="otpFields.otp" class="form-control" type="text">
            <slot v-if="errors && errors['otp']">
              <div class="text-danger" v-for="error in errors['otp']"
                   v-bind:key="error">
                <small>{{ error }}</small>
              </div>
            </slot>
          </div>
          <button @click="checkOtp(account)" class="btn btn-primary">
            <i v-if="process" class="fa fa-spinner fa-spin"></i>
            {{$trans('Continue')}}
          </button>
        </slot>
        <slot v-if="account.status != -1">
          <nav class="mb-3">
            <div class="nav nav-tabs" id="nav-tab" >
              <a class="nav-item nav-link" @click="tabActive = 'history'" v-bind:class="{'active':tabActive=='history'}" href="javascript:void(0)">{{$trans('History')}}</a>
              <a class="nav-item nav-link" @click="tabActive = 'transfer'" v-bind:class="{'active':tabActive=='transfer'}" href="javascript:void(0)">{{$trans('Transfer')}}</a>
              <a class="nav-item nav-link" @click="tabActive = 'Withdraw'" v-bind:class="{'active':tabActive=='Withdraw'}" href="javascript:void(0)">{{$trans('Withdraw')}}</a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div v-if="tabActive == 'history'" class="tab-pane active">
              <zalo-history v-bind:account_id="account._id"></zalo-history>
            </div>
            <div v-if="tabActive == 'transfer'" class="tab-pane active">
              <zalo-transfer v-bind:account_id="account._id"></zalo-transfer>
            </div>
            <div v-if="tabActive == 'Withdraw'" class="tab-pane active">
              <zalo-withdraw v-bind:account_id="account._id"></zalo-withdraw>
            </div>
          </div>
        </slot>
      </slot>
    </modal>
    <modal v-if="showHistory" v-bind:width="'70%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{$trans('Zalo history')}}
          <button @click="showHistory = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </button>
        </h5>
        <hr>
        <zalo-history-db></zalo-history-db>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "ZaloPay",
  data:function (){
    return {
      showDetail:false,
      addFields:{
        username:'',
        password:'',
        deposit_limit:0,
        deposit_status:0
      },
      updateFields:{},
      errors:{},
      alert:null,
      addForm:false,
      filterQuery:{
        page:1
      },
      zalo_accounts:null,
      editForm:false,
      process:false,
      account:null,
      otpFields:{
          otp:''
      },
      tabActive:'history',
      qr:null,
      showHistory:false,
      zalo_total_balance:null
    }
  },
  methods:{
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/zalopay',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.zalo_accounts = resp.data.accounts;
            this.zalo_total_balance = resp.data.total_balance;
          })
          .catch(err => {
            console.log(err)
          })
    },
    store:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/zalopay', data:this.addFields,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.addForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit:function (account){
      this.editForm = true;
      axios({url: this.$root.$data.api_url + '/api/zalopay/'+account._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;

          })
          .catch(err => {
            console.log(err)
          })
    },
    update:function (){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/zalopay/'+this.updateFields._id, data:this.updateFields,method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.editForm = false;
                this.index();
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy:function (account){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/zalopay/' + account._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail:function (account){
      this.showDetail = true;
      this.account = null

      axios({url: this.$root.$data.api_url + '/api/zalopay/'+account._id, method: 'GET'})
          .then(resp => {
            this.account = resp.data.data;
            this.qr = resp.data.qr;
          })
          .catch(err => {
            console.log(err)
          })
    },
    checkOtp:function (account){
      if( this.process == false){
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.otpFields
        data.id = account._id
        axios({url: this.$root.$data.api_url + '/api/zalopay/check_otp', data:data,method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if( error_code == 0){
                this.detail(account);
              }else{
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('zalo-channel');
    channel.bind('zalo-event', (e) => {

      if (this.zalo_accounts && this.zalo_accounts.data) {
        var arr = this.zalo_accounts.data;
        var tmp_arr = [];
        var current_id = e.zalo_account._id;
        arr.forEach(function (el, index) {
          if (current_id == el._id) {
            tmp_arr[index] = e.zalo_account;
          } else {
            tmp_arr[index] = el;
          }
        });
        this.zalo_accounts.data = tmp_arr;
      }
      if( this.account && this.showDetail && this.account._id == e.zalo_account._id){
          this.account = e.zalo_account;
      }

    });
  },
  computed:{
    list_zalo_accounts: function () {
      if (!this.zalo_accounts) {
        return null
      }
      if (!this.zalo_accounts.data || this.zalo_accounts.data.length < 0) {
        return null
      }
      return this.zalo_accounts.data
    }
  }
}
</script>

<style scoped>

</style>