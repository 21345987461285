<template>
  <div class="">
    <div class="d-flex mb-3">

      <select @change="filter" v-model="filterQuery.time" class="form-control w-200 mr-3">
        <option value="24">{{ $trans('Last 24h') }}</option>
        <option value="36">{{ $trans('Last 36h') }}</option>
        <option value="72">{{ $trans('Last 72h') }}</option>
        <option value="custom">{{ $trans('Custom') }}</option>
      </select>
      <input v-if="filterQuery.time =='custom'" @change="filter" v-model="filterQuery.custom_time" :placeholder="$trans('')" class="form-control w-250 mr-3" type="text">
    </div>
    <div class="table-responsive">
      <table v-if="tranList" class="table table-striped">
        <thead>
        <tr>
          <th>{{ $trans('ID') }}</th>
          <th class="text-right">{{ $trans('Original Amount') }}</th>
          <th class="text-right">{{ $trans('Fee') }}</th>
          <th>{{ $trans('Comment') }}</th>
          <th>{{ $trans('Desc') }}</th>
          <th>{{ $trans('Date') }}</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(trans,index) in tranList" v-bind:key="index">
              <td>{{trans.tranId}}</td>
              <td class="text-right">
                <span class="text-danger" v-if="trans.io == -1">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="-trans.originalAmount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
                <span class="text-success" v-if="trans.io == 1">
                  +<vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="trans.originalAmount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="trans.fee"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                {{trans.comment}}
              </td>
              <td>{{trans.desc}}</td>
              <td>{{trans.date}}</td>
          </tr>
        </tbody>
      </table>
      <slot v-else>
        <div v-if="histories && histories.data && histories.data.result == false">
          {{histories.data.errorDesc}}
        </div>
        <div v-else>{{$trans('No transactions found')}}</div>
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "History",
  props:{
    account_id: String,
  },
  data:function (){
    return {
      histories:null,
      filterQuery:{
          time:'24',
          custom_time:24
      }
    }
  },
  methods:{
      filter:function (){
          this.index()
      },
      index:function (){
        axios({url: this.$root.$data.api_url + '/api/momo/wallet/histories/'+this._props.account_id,params: this.filterQuery, method: 'GET'})
            .then(resp => {
              this.histories = resp.data.data;
            })
            .catch(err => {
              console.log(err)
            })
      }
  },
  created() {
    this.index()
  },
  computed:{
     tranList:function (){
        if( !this.histories){
            return null
        }
       if( !this.histories.data){
         return null
       }
        if( !this.histories.data.momoMsg){
            return  null
        }
        if( !this.histories.data.momoMsg.tranList){
            return null
        }
        let tranList =[];
        let listT = this.histories.data.momoMsg.tranList
       listT.sort(function(x, y){
         return y.clientTime - x.clientTime;
       })
       listT.forEach(el=>{
         let item = el
         let dateTime = new Date(el.clientTime);
         let date = dateTime.getFullYear() +"-"+(dateTime.getMonth()+1) + '-'+dateTime.getDate() +" "+dateTime.getHours()+
             ":"+dateTime.getMinutes()+
             ":"+dateTime.getSeconds()
         item.date = date;
         tranList.push(item)
       })
       //tranList.reverse()
       return  tranList
     },
  }
}
</script>

<style scoped>

</style>