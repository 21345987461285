<template>
  <div class="card">
    <div class="card-header">
      <h2 class="mr-auto">{{$trans('Reports')}}</h2>
    </div>
    <nav>
      <div class="nav nav-tabs" id="nav-tab" >
        <a class="nav-item nav-link" @click="tabActive = 'ib'" v-bind:class="{'active':tabActive=='ib'}" href="javascript:void(0)">{{$trans('Internet Banking')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'report-momo'" v-bind:class="{'active':tabActive=='report-momo'}" href="javascript:void(0)">{{$trans('MoMo')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'report-zalo'" v-bind:class="{'active':tabActive=='report-zalo'}" href="javascript:void(0)">{{$trans('Zalo')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'auto'" v-bind:class="{'active':tabActive=='auto'}" href="javascript:void(0)">{{$trans('Auto')}}</a>
        <a class="nav-item nav-link" @click="tabActive = 'deposit-bank-report'" v-bind:class="{'active':tabActive=='deposit-bank-report'}" href="javascript:void(0)">{{$trans('Deposit Bank')}}</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div v-if="tabActive == 'ib'" class="tab-pane active">
        <report-ib-transaction></report-ib-transaction>
      </div>
      <div v-if="tabActive == 'report-momo'" class="tab-pane active">
        <report-momo></report-momo>
      </div>
      <div v-if="tabActive == 'report-zalo'" class="tab-pane active">
        <report-zalo></report-zalo>
      </div>
      <div v-if="tabActive == 'deposit-bank-report'" class="tab-pane active">
        <deposit-bank-report></deposit-bank-report>
      </div>
      <div v-if="tabActive == 'auto'" class="tab-pane active">
        <nav>
          <div class="nav nav-tabs" >
            <a class="nav-item nav-link" @click="subtabActive = 'ib'" v-bind:class="{'active':subtabActive=='ib'}" href="javascript:void(0)">{{$trans('IB')}}</a>
            <a class="nav-item nav-link" @click="subtabActive = 'report-ib-screenshots'" v-bind:class="{'active':subtabActive=='report-ib-screenshots'}" href="javascript:void(0)">{{$trans('Screenshots')}}</a>
          </div>
        </nav>
        <div class="tab-content">
          <div v-if="subtabActive == 'ib'" class="tab-pane active">
            <report-ib></report-ib>
          </div>
          <div v-if="subtabActive == 'report-ib-screenshots'" class="tab-pane active">
            <report-ib-screenshots></report-ib-screenshots>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Reports",
  data:function (){
    return {
      tabActive:'ib',
      subtabActive:'ib'
    }
  }
}
</script>

<style scoped>

</style>