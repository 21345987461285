<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex report-header">
        <h4 class="mb-0 mr-auto">{{ $trans('Deposit Banks') }}</h4>
        <div class="d-flex flex-row-reverse">
          <select v-model="filterQuery.filterby" class="custom-select w150" @change="index">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="month">This Month</option>
            <option value="last_month">Last Month</option>
            <option value="year">This Year</option>
          </select>
        </div>
      </div>
      <hr>
      <slot v-if="bank_results && bank_results.length">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Bank') }}</th>
              <th class="nowrap text-right">Total Transaction</th>
              <th class="nowrap text-right">Total Deposit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(bank,i) in bank_results" :key="i">
              <td>{{ bank.name }} - {{ bank.account_number }}</td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-bind:value="bank.count"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="VND"
                    v-bind:value="bank.total"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th>{{ $trans('Total:') }}</th>
              <td class="text-right">
                  <strong>
                    <vue-numeric
                        currency-symbol-position="suffix"
                        currency=""
                        v-bind:value="total_results.total_transaction"
                        v-bind:read-only="true"
                        v-bind:precision="0">
                    </vue-numeric>
                  </strong>
              </td>
              <td class="text-right">
                <strong>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency="VND"
                      v-bind:value="total_results.total_amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </strong>
              </td>
            </tr>
            </tfoot>

          </table>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DepositBank",
  data: function () {
    return {
      filterQuery: {
        filterby: 'today'
      },
      statistic_data: null,
      results: null,
      banks: null,
    }
  },
  methods: {
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/statistic/deposit-banks', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.statistic_data = resp.data;
            this.banks = resp.data.banks;
            this.results = resp.data.results;
          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.index()
  },
  computed: {
    report_results: function () {
      if (!this.results) {
        return null
      }
      return this.statistic_data.results[0]
    },
    bank_results:function (){
        if( !this.banks){
            return []
        }
      if( !this.report_results){
        return []
      }
      let banks = this.banks
      let report_results = this.report_results
      let bank_results=[]
      banks.forEach(el=>{
          let item = el
          if( report_results[el._id]){
            item.count = report_results[el._id]
          }else {
            item.count = 0
          }
        if( report_results[el._id+'_total']){
          item.total = report_results[el._id+'_total']
        }else {
          item.total =0
        }
        bank_results.push(item)

      })
      return  bank_results
    },
    total_results: function () {
      let total_transaction = 0
      let total_amount = 0
      if( this.bank_results){
        let bank_results = this.bank_results
        bank_results.forEach((el)=>{
          total_transaction = total_transaction+ el.count
          total_amount = total_amount+ el.total
        })
      }
      return {
        total_transaction: total_transaction,
        total_amount: total_amount
      }
    }

  }
}
</script>

<style scoped>

</style>