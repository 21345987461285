<template>
  <div v-if="$can('system-settings')" class="system-setting">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <h4 class="mb-0">{{ $trans('System settings') }}</h4>
        </div>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Maintenance') }}</label>
          <div class="form-check mb-1">
            <input true-value="yes" false-value="no" id="payout_group" type="checkbox" v-model="settings.maintenance">
            <label class="form-check-label" for="payout_group">
              <span>{{ $trans('On/Off') }}</span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $trans('Deposit methods') }}</label>
          <div class="form-check mb-1">
            <input true-value="on" false-value="off" id="deposit_method_ib" type="checkbox" v-model="settings.deposit_method_ib">
            <label class="form-check-label" for="deposit_method_ib">
              <span>{{ $trans('Internet banking') }}</span>
            </label>
          </div>
          <div class="form-check mb-1">
            <input true-value="on" false-value="off" id="deposit_method_bank_transfer" type="checkbox" v-model="settings.deposit_method_bank_transfer">
            <label class="form-check-label" for="deposit_method_bank_transfer">
              <span>{{ $trans('Bank transfer') }}</span>
            </label>
          </div>
          <div v-if="current_currency =='VND'" class="form-check mb-1">
            <input true-value="on" false-value="off" id="deposit_method_momo" type="checkbox" v-model="settings.deposit_method_momo">
            <label class="form-check-label" for="deposit_method_momo">
              <span>{{ $trans('MoMo wallet') }}</span>
            </label>
          </div>
          <div v-if="current_currency =='VND'" class="form-check mb-1">
            <input true-value="on" false-value="off" id="deposit_method_zalo" type="checkbox" v-model="settings.deposit_method_zalo">
            <label class="form-check-label" for="deposit_method_zalo">
              <span>{{ $trans('Zalopay') }}</span>
            </label>
          </div>
          <div v-if="current_currency =='VND'" class="form-check mb-1">
            <input true-value="on" false-value="off" id="deposit_method_qrcode" type="checkbox" v-model="settings.deposit_method_qrcode">
            <label class="form-check-label" for="deposit_method_qrcode">
              <span>{{ $trans('qrcode') }}</span>
            </label>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $trans('Use MOMO Account') }}</label>
          <select class="form-control w-250" v-model="settings.use_momo_accounts">
            <option value="IN_SITE">IN SITE</option>
            <option value="OUT_SITE">OUT SITE</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $trans('IB banks support') }}</label>
          <div class="list-banks">
            <div v-for="(bank,key) in bank_supports" v-bind:key="key" class="form-check mb-1">
              <input :id="'ib_'+key" :value="key" type="checkbox" v-model="settings.ib_banks_support">
              <label class="form-check-label" :for="'ib_'+key">
                <span>{{ bank }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>{{ $trans('Bank transfer banks support') }}</label>
          <div class="list-banks">
            <div v-for="(bank,key) in bank_supports" v-bind:key="key" class="form-check mb-1">
              <input :id="'bank_transfer_'+key" :value="key" type="checkbox" v-model="settings.bank_transfer_banks_support">
              <label class="form-check-label" :for="'bank_transfer_'+key">
                <span>{{ bank }}</span>
              </label>
            </div>
          </div>
        </div>
        <button @click="save_settings" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{ $trans('Save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "System",
  data: function () {
    return {
      settings: {},
      process: false,
      alert: null,
      errors: {},
      deposit_methods: {},
      current_currency:this.$root.current_currency,
    }
  },
  methods: {
    get_settings: function () {
      axios({url: this.$root.$data.api_url + '/api/settings/system', params: {}, method: 'GET'})
          .then(resp => {
            this.settings = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    save_settings: function () {
      this.alert = null
      if (this.process == false) {
        this.process = true;
        let data = this.settings;
        axios({url: this.$root.$data.api_url + '/api/settings/system', data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.alert = {
                  type: 'success',
                  message: resp.data.message
                }
                this.get_settings();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;

            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.get_settings();
  },
  computed:{
    bank_supports:function (){
      return this.$root.$data.global_settings.bank_supports;
    },
  }
}
</script>

<style scoped>

</style>