<template>
    <div class="">
      <div v-if="!this._props.keyword" class="d-flex mb-3">
        <button @click="sync_histories" style="font-size: 14px" class="btn btn-info btn-sm mr-3">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{ $trans('Sync') }}
        </button>
        <input @change="historyFilter" v-model="historyFilterQuery.keyword"
               :placeholder="$trans('Account, tranId, comment')" class="form-control w-250 mr-3" type="text">
        <select @change="historyFilter" v-model="historyFilterQuery.io" class="form-control w-120 mr-3">
          <option value="all">{{ $trans('All IO') }}</option>
          <option value="1">{{ $trans('IN') }}</option>
          <option value="-1">{{ $trans('OUT') }}</option>
        </select>
        <select @change="historyFilter" v-model="historyFilterQuery.filterby" class="form-control w-120 mr-3">
          <option value="all">{{ $trans('All date') }}</option>
          <option value="today">{{ $trans('Today') }}</option>
          <option value="yesterday">{{ $trans('Yesterday') }}</option>
          <option value="month">{{ $trans('This month') }}</option>
          <option value="last_month">{{ $trans('Last month') }}</option>
        </select>
        <button @click="do_export" class="btn btn-primary mr-3">
          <i v-if="process_export" class="fa fa-spinner fa-spin"></i>
          {{$trans('Export')}}
        </button>
        <download-csv v-if="json_data && json_data.length" :labels="labels" :data="json_data" :name="'momo_histories.csv'">
          <button class="btn btn-primary">{{ $trans('Download') }}</button>
        </download-csv>
      </div>
      <slot v-if="histories && histories.data">
        <div class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ histories.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ histories.total }}</strong></small>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Account') }}</th>
              <th>{{ $trans('ID') }}</th>
              <th>{{ $trans('Partner Name') }}</th>
              <th>{{ $trans('Partner ID') }}</th>
              <th class="text-right nowrap">{{ $trans('Original Amount') }}</th>
              <th class="text-right nowrap ">{{ $trans('Fee') }}</th>
              <th>{{ $trans('Ref No') }}</th>
              <th>{{ $trans('Desc') }}</th>
              <th class="nowrap">{{ $trans('Date') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(history,index) in histories.data" v-bind:key="index">
              <td class="nowrap">
                {{ history.username }}
                <slot v-if="history.account"> - {{ history.account.fullname }}</slot>
              </td>
              <td>{{ history.tranId }}</td>
              <td>{{ history.partnerName }}</td>
              <td>{{ history.partnerId }}</td>
              <td class="text-right nowrap">
                <span class="text-danger" v-if="history.io == -1">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="-history.originalAmount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
                <span class="text-success" v-if="history.io == 1">
                  +<vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="history.originalAmount"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                  </vue-numeric>
                </span>
              </td>
              <td class="text-right nowrap">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="history.fee"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                {{ history.comment }}
              </td>
              <td>{{ history.desc }}</td>
              <td class="nowrap">{{ history.date }}</td>
              <td>
                  <span v-if="history.is_confirmed==1"
                        class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Confirmed') }}</span>
                <span v-else-if="history.is_confirmed==0"
                      class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Unconfirmed') }}</span>
                <span v-else>-</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="histories && histories.data && histories.last_page"
                  :page-count="histories.last_page"
                  :click-handler="historyPginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HistoryDb",
  props:{
    keyword: String,
  },
  data:function (){
    return {
      historyFilterQuery: {
        page: 1,
        keyword:"",
        filterby:'all',
        io:'all'
      },
      process:false,
      process_export:false,
      histories:null,
      export_transactions:null,
      labels:{
        type:"Type",
        id:"ID",
        account:'Account',
        partnerName:'Partner Name',
        partnerId:'Partner ID',
        originalAmount:'Original Amount',
        desc:'Desc',
        comment:'Ref No',
        is_confirmed:'System confirm',
        fee:'Fee',
        date:'Date',
      }
    }
  },
  methods:{
    historyFilter:function (){
      this.historyFilterQuery.page = 1
      this.get_histories()
    },
    historyPginate:function (pageNum){
      this.historyFilterQuery.page = pageNum;
      this.get_histories();
    },
    get_histories: function () {
      this.formHistories = true
      axios({url: this.$root.$data.api_url + '/api/momo/all_histories', params: this.historyFilterQuery, method: 'GET'})
          .then(resp => {
            this.histories = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    sync_histories: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/momo/sync_histories', data: [], method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.get_histories()
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    do_export:function (){
      if (this.process_export == false) {
        this.process_export = true;
        this.export_transactions = null;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/momo/export/history', params: this.historyFilterQuery, method: 'GET'})
            .then(resp => {
              this.export_transactions = resp.data;
              this.process_export = false;
            })
            .catch(err => {
              console.log(err)
              this.process_export = false;
            })
      }
    }
  },
  created() {
    this.get_histories()
  },
  computed:{
    json_data:function (){
      if( !this.export_transactions){
        return null
      }
      let export_transactions = this.export_transactions
      let data =[]
      export_transactions.forEach((el)=>{
        let originalAmount  =el.originalAmount
        let is_confirmed ='-';
        if( el.io == -1){
          originalAmount = originalAmount *-1
        }
        if( el.is_confirmed == 1){
            is_confirmed ='Yes';
        }else {
          if( el.is_confirmed == 0){
            is_confirmed ='No';
          }
        }
        let type ='-'
        if( el.io == 1) type ='IN'
        if( el.io == -1) type ='OUT'
        let item = {
          type: type,
          id:el.tranId,
          account:el.username +'-'+el.account.fullname,
          partnerName:el.partnerName,
          partnerId:el.partnerId,
          originalAmount:originalAmount,
          fee:el.fee || 0,
          desc:el.desc,
          comment:el.comment,
          is_confirmed:is_confirmed,
          date:el.date,
        }
        data.push(item)
      })
      return data
    }
  }
}
</script>

<style scoped>

</style>