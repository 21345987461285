<template>
  <div class="dashboard">
    <div class="card">
      <div class="card-header d-flex">
        <h2 class="mr-auto" style="line-height: normal;">{{ $trans('Dashboard') }}</h2>
        <div class="d-flex flex-row-reverse">
          <select v-model="filterQuery.filterby" class="custom-select w150" @change="triger_filter">
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="month">This Month</option>
            <option value="last_month">Last Month</option>
            <option value="year">This Year</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-7">
          <div class="row">
            <div class="col-sm-4">
              <div class="box-count">
                <div class="icon">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                </div>
                <div class="content">
                  <h6>{{ $trans('Revenue') }}</h6>
                  <span>
                      <vue-numeric
                          currency-symbol-position="suffix"
                          :currency="current_currency"
                          v-bind:value="report_data.revenue"
                          v-bind:read-only="true"
                          v-bind:precision="$get_precision()">
                      </vue-numeric>
                  </span>
                  <span class="evolution" :class="{ 'up': evolution.revenue > 0, 'down': evolution.revenue < 0 }">
                      <svg v-if="evolution.revenue > 0" viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                           stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1"><line x1="12" y1="19" x2="12" y2="5"></line><polyline
                          points="5 12 12 5 19 12"></polyline></svg>
                      <svg v-if="evolution.revenue < 0" viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                           stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><polyline
                          points="19 12 12 19 5 12"></polyline></svg>
                      <vue-numeric v-if="evolution.revenue < 0"
                                   currency-symbol-position="suffix"
                                   currency="%"
                                   v-bind:value="evolution.revenue*-1"
                                   v-bind:read-only="true"
                                   v-bind:precision="2">
                        </vue-numeric>
                        <vue-numeric v-if="evolution.revenue > 0"
                                     currency-symbol-position="suffix"
                                     currency="%"
                                     v-bind:value="evolution.revenue"
                                     v-bind:read-only="true"
                                     v-bind:precision="2">
                        </vue-numeric>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="box-count">
                <div class="icon bg-primary">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                       stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                </div>
                <div class="content">
                  <h6>{{ $trans('Profit') }}</h6>
                  <span>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            :currency="current_currency"
                            v-bind:value="report_data.profit"
                            v-bind:read-only="true"
                            v-bind:precision="$get_precision()">
                        </vue-numeric>
                  </span>
                  <span class="evolution" :class="{ 'up': evolution.profit > 0, 'down': evolution.profit < 0 }">
                      <svg v-if="evolution.profit > 0" viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                           stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1"><line x1="12" y1="19" x2="12" y2="5"></line><polyline
                          points="5 12 12 5 19 12"></polyline></svg>
                      <svg v-if="evolution.profit < 0" viewBox="0 0 24 24" width="12" height="12" stroke="currentColor"
                           stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                           class="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><polyline
                          points="19 12 12 19 5 12"></polyline></svg>
                      <vue-numeric v-if="evolution.profit < 0"
                                   currency-symbol-position="suffix"
                                   currency="%"
                                   v-bind:value="evolution.profit*-1"
                                   v-bind:read-only="true"
                                   v-bind:precision="2">
                        </vue-numeric>
                        <vue-numeric v-if="evolution.profit > 0"
                                     currency-symbol-position="suffix"
                                     currency="%"
                                     v-bind:value="evolution.profit"
                                     v-bind:read-only="true"
                                     v-bind:precision="2">
                        </vue-numeric>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="box-count">
                <div class="icon bg-success">
                  <svg data-v-7d622f5c="" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor"
                       stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                    <polygon data-v-7d622f5c="" points="12 2 2 7 12 12 22 7 12 2"></polygon>
                    <polyline data-v-7d622f5c="" points="2 17 12 22 22 17"></polyline>
                    <polyline data-v-7d622f5c="" points="2 12 12 17 22 12"></polyline>
                  </svg>
                </div>
                <div class="content">
                  <h6>{{ $trans('Transactions') }}</h6>
                  <span>
                        <vue-numeric
                            currency-symbol-position="suffix"
                            currency=""
                            v-bind:value="report_data.total_success_transaction"
                            v-bind:read-only="true"
                            v-bind:precision="0">
                        </vue-numeric>
                       <span class="text-dark">
                         /
                         <vue-numeric
                             currency-symbol-position="suffix"
                             currency=""
                             v-bind:value="report_data.total_transaction"
                             v-bind:read-only="true"
                             v-bind:precision="0">
                       </vue-numeric>
                       </span>
                    </span>
                  <div class="d-flex">
                        <span class="evolution"
                              :class="{ 'up': evolution.total_success_transaction > 0, 'down': evolution.total_success_transaction < 0 }">
                            <svg v-if="evolution.total_success_transaction > 0" viewBox="0 0 24 24" width="12"
                                 height="12" stroke="currentColor"
                                 stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                 class="css-i6dzq1"><line x1="12" y1="19" x2="12" y2="5"></line><polyline
                                points="5 12 12 5 19 12"></polyline></svg>
                            <svg v-if="evolution.total_success_transaction < 0" viewBox="0 0 24 24" width="12"
                                 height="12" stroke="currentColor"
                                 stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                 class="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><polyline
                                points="19 12 12 19 5 12"></polyline></svg>
                            <vue-numeric v-if="evolution.total_success_transaction < 0"
                                         currency-symbol-position="suffix"
                                         currency="%"
                                         v-bind:value="evolution.total_success_transaction*-1"
                                         v-bind:read-only="true"
                                         v-bind:precision="2">
                              </vue-numeric>
                              <vue-numeric v-if="evolution.total_success_transaction > 0"
                                           currency-symbol-position="suffix"
                                           currency="%"
                                           v-bind:value="evolution.total_success_transaction"
                                           v-bind:read-only="true"
                                           v-bind:precision="2">
                              </vue-numeric>
                        </span> /
                    <span class="evolution"
                          :class="{ 'up': evolution.total_transaction > 0, 'down': evolution.total_transaction < 0 }">
                          <svg v-if="evolution.total_transaction > 0" viewBox="0 0 24 24" width="12" height="12"
                               stroke="currentColor"
                               stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                               class="css-i6dzq1"><line x1="12" y1="19" x2="12" y2="5"></line><polyline
                              points="5 12 12 5 19 12"></polyline></svg>
                          <svg v-if="evolution.total_transaction < 0" viewBox="0 0 24 24" width="12" height="12"
                               stroke="currentColor"
                               stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                               class="css-i6dzq1"><line x1="12" y1="5" x2="12" y2="19"></line><polyline
                              points="19 12 12 19 5 12"></polyline></svg>
                          <vue-numeric v-if="evolution.total_transaction < 0"
                                       currency-symbol-position="suffix"
                                       currency="%"
                                       v-bind:value="evolution.total_transaction*-1"
                                       v-bind:read-only="true"
                                       v-bind:precision="2">
                            </vue-numeric>
                            <vue-numeric v-if="evolution.total_transaction > 0"
                                         currency-symbol-position="suffix"
                                         currency="%"
                                         v-bind:value="evolution.total_transaction"
                                         v-bind:read-only="true"
                                         v-bind:precision="2">
                            </vue-numeric>
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <slot v-if="results && results.length && transaction_revenue_chart">
              <GChart
                  type="LineChart"
                  :data="report_data.revenue_chart_data"
                  :options="transaction_revenue_chart.options"
              />
            </slot>
            <slot v-else>
              {{ $trans('No data') }}
            </slot>
          </div>
        </div>
        <div v-if="current_currency =='VND'" class="col-sm-3">
          <widget-ib-report :filterby="filterQuery.filterby"></widget-ib-report>
          <widget-momo-report :filterby="filterQuery.filterby"></widget-momo-report>
          <widget-zalo-report :filterby="filterQuery.filterby"></widget-zalo-report>
        </div>
        <div class="col-sm-2">
          <widget-auto-status></widget-auto-status>
          <widget-ib-online></widget-ib-online>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "Dashboard",
  data: function () {
    return {
      user: null,
      filterQuery: {
        filterby: 'today'
      },
      statics_data: null,
      transaction_revenue_chart: null,
      results: null,
      current_currency: this.$root.current_currency,
      extra_transaction: 0,
      extra_success_transaction: 0,
      extra_profit: 0,
      extra_revenue: 0,
    }
  },
  methods: {
    triger_filter: function () {
      this.get_statistic()
    },
    get_statistic: function () {
      axios({url: this.$root.$data.api_url + '/api/statistic', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.statics_data = resp.data;
            this.results = resp.data.results;
            this.transaction_revenue_chart = resp.data.transaction_revenue_chart;
          })
          .catch(err => {
            console.log(err)
          })
    },


  },
  created() {
    this.triger_filter()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('deposit-channel');
    channel.bind('deposit-event', (e) => {
      if (this.current_currency == e.currency && (this.filterQuery.filterby == 'today' || this.filterQuery.filterby == 'month' || this.filterQuery.filterby == 'year')) {
        if (e.action == 'created') {
          this.extra_transaction = this.extra_transaction + 1

        } else {
          let status = e.transaction.status
          if (status == 1) {
            this.extra_success_transaction = this.extra_success_transaction + 1
            this.extra_profit = this.extra_profit + e.transaction.fee
            this.extra_revenue = this.extra_revenue + e.transaction.amount
          }
        }
      }

    });

  },
  computed: {
    report_data: function () {
      if (!this.results) {
        return [];
      }
      let data = this.results
      let revenue_chart_data = [
        ['Time', 'Revenue', 'Transactions']
      ]
      let total_transaction = 0;
      let total_success_transaction = 0;
      let profit = 0;
      let revenue = 0;
      data.forEach((el) => {
        total_transaction = total_transaction + el.total_transaction;
        profit = profit + el.profit
        revenue = revenue + el.revenue
        total_success_transaction = total_success_transaction + el.total_success_transaction
        let item_revenue_chart_data = [
          el.vAxisTitle,
          el.revenue,
          el.total_transaction,
        ]
        revenue_chart_data.push(item_revenue_chart_data);

      })
      return {
        revenue_chart_data: revenue_chart_data,
        total_transaction: total_transaction + this.extra_transaction,
        profit: profit + this.extra_profit,
        revenue: revenue + this.extra_revenue,
        total_success_transaction: total_success_transaction + this.extra_success_transaction
      }

    },

    last_data: function () {
      let data = {
        profit: 0,
        revenue: 0,
        total_success_transaction: 0,
        total_transaction: 0
      }
      if (this.statics_data) {
        let last_data = this.statics_data.last_data
        if (last_data.length > 0) {
          data = {
            profit: last_data[0]['profit'],
            revenue: last_data[0]['revenue'],
            total_success_transaction: last_data[0]['total_success_transaction'],
            total_transaction: last_data[0]['total_transaction']
          }
        }
      }
      return data
    },
    evolution: function () {
      let this_profit = this.report_data.profit
      let this_revenue = this.report_data.revenue
      let last_profit = this.last_data.profit
      let last_revenue = this.last_data.revenue
      let last_total_transaction = this.last_data.total_transaction
      let this_total_transaction = this.report_data.total_transaction
      let last_total_success_transaction = this.last_data.total_success_transaction
      let this_total_success_transaction = this.report_data.total_success_transaction

      let profit_percent = 100
      let revenue_percent = 100
      let total_transaction_percent = 100
      let total_success_transaction_percent = 100
      if (last_profit > 0) {
        profit_percent = ((this_profit - last_profit) / last_profit) * 100
      }
      if (last_revenue > 0) {
        revenue_percent = ((this_revenue - last_revenue) / last_revenue) * 100
      }
      if (last_total_transaction > 0) {
        total_transaction_percent = ((this_total_transaction - last_total_transaction) / last_total_transaction) * 100
      }
      if (last_total_success_transaction > 0) {
        total_success_transaction_percent = ((this_total_success_transaction - last_total_success_transaction) / last_total_success_transaction) * 100
      }
      return {
        profit: profit_percent,
        revenue: revenue_percent,
        total_transaction: total_transaction_percent,
        total_success_transaction: total_success_transaction_percent
      }
    },
  }
}
</script>

<style scoped>

</style>