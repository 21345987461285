<template>
  <div class="">
    <div class="d-flex mb-3">
      <select @change="filter" v-model="filterQuery.time" class="form-control w-200 mr-3">
        <option value="24">{{ $trans('Last 24h') }}</option>
        <option value="36">{{ $trans('Last 36h') }}</option>
        <option value="72">{{ $trans('Last 72h') }}</option>
        <option value="custom">{{ $trans('Custom') }}</option>
      </select>
      <input v-if="filterQuery.time =='custom'" @change="filter" v-model="filterQuery.custom_time" :placeholder="$trans('')" class="form-control w-250 mr-3" type="text">
    </div>
    <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
      {{alert.message}}
    </div>
    <div class="table-responsive">
      <table v-if="transactions" class="table table-striped">
        <thead>
        <tr>
          <th>{{ $trans('ID') }}</th>
          <th class="text-right nowrap">{{ $trans('Original Amount') }}</th>
          <th class="text-right nowrap">{{ $trans('Fee') }}</th>
          <th>{{ $trans('Comment') }}</th>
          <th>{{ $trans('Status') }}</th>
          <th class="nowrap">{{ $trans('Date') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(trans,index) in transactions" v-bind:key="index">
          <td>{{trans.transid}}</td>
          <td class="text-right nowrap">
                <span class="text-danger" v-if="trans.sign == -1">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="-trans.amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
            <span class="text-success" v-if="trans.sign == 1">
                  +<vue-numeric
                currency-symbol-position="suffix"
                :currency="'VND'"
                v-bind:value="trans.amount"
                v-bind:read-only="true"
                v-bind:precision="0">
                  </vue-numeric>
                </span>
          </td>
          <td class="text-right nowrap">
            <vue-numeric
                currency-symbol-position="suffix"
                :currency="'VND'"
                v-bind:value="trans.userfeeamt"
                v-bind:read-only="true"
                v-bind:precision="0">
            </vue-numeric>
          </td>
          <td>
            {{trans.description}}
          </td>
          <td>
            <span v-if="trans.transstatus == 1" class="badge  badge-success badge-pill">{{$trans('Success')}}</span>
          </td>
          <td class="nowrap">{{trans.date}}</td>
        </tr>
        </tbody>
      </table>
      <slot v-else>
        {{$trans('No transactions found')}}
      </slot>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "History",
  props:{
    account_id: String,
  },
  data:function (){
      return {
        filterQuery:{
          time:24,
          custom_time:24
        },
        histories:null,
        transList:null,
        alert:null
      }
  },
  methods:{
    filter:function (){
      this.index()
    },
    index:function (){
      axios({url: this.$root.$data.api_url + '/api/zalopay/get_history/'+this._props.account_id,params: this.filterQuery, method: 'GET'})
          .then(resp => {
            let error_code = resp.data.error_code;
            if( error_code == 0){
              this.histories = resp.data.data;
              this.transList = resp.data.data.transList;
            }else{
              this.alert = {
                type: 'warning',
                message: resp.data.message
              }
            }

          })
          .catch(err => {
            console.log(err)
          })
    }
  },
  created() {
    this.index()
  },
  computed:{
    transactions:function (){
        if (!this.transList){
            return []
        }

      let tranList =[];
      let listT = this.transList
      listT.forEach(el=>{
        let item = el
        let dateTime = new Date(el.reqdate);
        let date = dateTime.getFullYear() +"-"+(dateTime.getMonth()+1) + '-'+dateTime.getDate() +" "+dateTime.getHours()+
            ":"+dateTime.getMinutes()+
            ":"+dateTime.getSeconds()
        item.date = date;
        tranList.push(item)
      })

      return  tranList
    }
  }
}
</script>

<style scoped>

</style>