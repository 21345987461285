<template>
  <div class="card">
      <div class="card-header">
        <h2>{{ $trans('Payout') }}</h2>
      </div>
      <div class="card-body">
        <slot v-if="transactions && transactions.data">
          <div class="table-responsive">
            <div class="list-info mb-3">
              <small>{{ $trans('Results') }}: <strong>{{ transactions.data.length }}</strong> {{ $trans('of') }} <strong>{{ transactions.total }}</strong></small>
            </div>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>{{ $trans('Ref number') }}</th>
                <th>{{ $trans('Client') }}</th>
                <th>{{ $trans('Bank') }}</th>
                <th>{{ $trans('Account name') }}</th>
                <th>{{ $trans('Account number') }}</th>
                <th class="text-right">{{ $trans('Amount') }}</th>
                <th>{{ $trans('Date') }}</th>
                <th>{{ $trans('Status') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(transaction,index) in transactions.data" v-bind:key="index">
                <td>
                    {{transaction.ref_number}}
                </td>
                <td>
                    {{transaction.user.username}}
                </td>
                <td>
                  <slot v-if="transaction.bank">{{ transaction.bank.name }}</slot>
                </td>
                <td>
                  <slot v-if="transaction.bank">{{ transaction.bank.account_name }}</slot>
                </td>
                <td>
                  <slot v-if="transaction.bank">{{ transaction.bank.account_number }}</slot>
                </td>
                <td class="text-right text-success">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="current_currency"
                      v-bind:value="transaction.amount"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
                <td>{{ transaction.created_at }}</td>
                <td>
                  <span v-if="transaction.status==1" class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
                  <span v-if="transaction.status==0" class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
                  <span v-if="transaction.status==-1" class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
                </td>
                <td class="text-right">
                  <a @click="detail(transaction._id)" href="javascript:void(0)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-external-link">
                      <path data-v-52ca7221="" d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                      <polyline data-v-52ca7221="" points="15 3 21 3 21 9"></polyline>
                      <line data-v-52ca7221="" x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginate v-if="transactions && transactions.data && transactions.last_page"
                    :page-count="transactions.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </slot>
      </div>
    <modal v-if="showDetail && $can('view-deposit') && transaction" v-bind:width="'600px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Transaction detail') }}
          <button @click="showDetail = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <table class="table table-striped">
          <tbody>
          <tr>
            <th>{{ $trans('Ref Number') }}</th>
            <td>{{ transaction.ref_number }}</td>
          </tr>
          <tr>
            <th>{{ $trans('Client') }}</th>
            <td>{{ transaction.user.username }}</td>

          </tr>
          <tr>
            <th>{{ $trans('Deposit account') }}</th>
            <td>
              <slot v-if="transaction.bank">{{ transaction.bank.name }}</slot>
            </td>

          </tr>
          <tr>
            <th>{{ $trans('Account name') }}</th>
            <td>
              <slot v-if="transaction.bank">{{ transaction.bank.account_name }}</slot>
            </td>

          </tr>
          <tr>
            <th>{{ $trans('Account number') }}</th>
            <td>
              <slot v-if="transaction.bank">{{ transaction.bank.account_number }}</slot>
            </td>

          </tr>
          <tr>
            <th>{{ $trans('Amount') }}</th>
            <td class=" text-success">
              <vue-numeric
                  currency-symbol-position="suffix"
                  :currency="current_currency"
                  v-bind:value="transaction.amount"
                  v-bind:read-only="true"
                  v-bind:precision="0">
              </vue-numeric>
            </td>

          </tr>
          <tr>
            <th>{{ $trans('Date') }}</th>
            <td>{{ transaction.created_at }}</td>

          </tr>
          <tr>
            <th>{{ $trans('Status') }}</th>
            <td>
              <span v-if="transaction.status==1" class="mr-1 mt-1 badge badge-success badge-pill">{{ $trans('Success') }}</span>
              <span v-if="transaction.status==0" class="mr-1 mt-1 badge badge-warning badge-pill">{{ $trans('Hold') }}</span>
              <span v-if="transaction.status==-1" class="mr-1 mt-1 badge badge-danger badge-pill">{{ $trans('Reject') }}</span>
            </td>
          </tr>
          <tr v-if="transaction.logs && transaction.logs.length">
            <th style="vertical-align: top">{{ $trans('Logs') }}</th>
            <td>
              <span class="d-block" v-for="(log,k) in transaction.logs" v-bind:key="k">{{ log }}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <div v-if="$can('confirm-payout')" class="form-actions">
          <slot v-if="transaction.status == 0">
            <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
              {{ alert.message }}
            </div>
            <button @click="confirm_transaction(transaction._id)" class="btn btn-success mr-3">
              <i v-if="process" class="fa fa-spinner fa-spin"></i>
              {{ $trans('Confirm Transaction') }}
            </button>
            <button @click="reject_transaction(transaction._id)" class="btn btn-danger">
              <i v-if="process_reject" class="fa fa-spinner fa-spin"></i>
              {{ $trans('Reject Transaction') }}
            </button>
          </slot>
        </div>

      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "Payout",
  data: function () {
    return {
      transactions: null,
      filterQuery: {
        page: 1,
      },
      current_currency: this.$root.current_currency,
      transaction: null,
      showDetail: false,
      errors: {},
      alert: null,
      process: false,
      process_reject: false,
    }
  },
  methods: {
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index: function () {
      axios({url: this.$root.$data.api_url + '/api/payout/transactions', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.transactions = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail: function (id) {
      this.showDetail = true
      this.tabActive = 'detail'
      axios({url: this.$root.$data.api_url + '/api/payout/transactions/' + id, params: {}, method: 'GET'})
          .then(resp => {
            this.transaction = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    confirm_transaction: function (id) {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = {
          reason: this.reason
        }
        axios({url: this.$root.$data.api_url + '/api/payout/transactions/confirm/' + id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.detail(id);
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    reject_transaction: function (id) {
      if (this.process_reject == false) {
        this.process_reject = true;
        this.errors = {}
        this.alert = null
        let data = {
          reason: this.reason
        }
        axios({url: this.$root.$data.api_url + '/api/payout/transactions/reject/' + id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.detail(id);
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_reject = false;
            })
            .catch(err => {
              console.log(err)
              this.process_reject = false;
            })
      }
    }
  },
  created() {
    this.index();
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('payout-channel');
    channel.bind('payout-event', (e) => {
      if (this.current_currency == e.currency) {
        if (this.transactions && this.transactions.data) {
          if (e.action == 'created') {
            this.transactions.data.unshift(e.transaction);
          } else {
            var arr = this.transactions.data;
            var tmp_arr = [];
            var current_id = e.transaction._id;
            arr.forEach(function (el, index) {
              if (current_id == el._id) {
                tmp_arr[index] = e.transaction;
              } else {
                tmp_arr[index] = el;
              }
            });
            this.transactions.data = tmp_arr;
          }
        }
      }
    });
  }
}
</script>

<style scoped>

</style>