<template>
  <div class="card">
    <div class="card-header d-flex">
      <h2 class="mr-auto ">{{ $trans('Momo Wallet') }}</h2>
      <button v-if="$can('view-momo-history')" @click="formHistories = true" class="btn btn-info btn-sm mr-3">
        {{ $trans('View Histories') }}
      </button>
      <button v-if="$can('add-momo-wallet')" @click="addForm = true" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
          <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        {{ $trans('Add new') }}
      </button>
    </div>
    <div class="d-flex mb-3">
      <input @change="filter" v-model="filterQuery.keyword" :placeholder="$trans('Username or name')"
             class="form-control w-250 mr-3" type="text">
      <select  @change="filter" class="form-control w-100" v-model="filterQuery.type">
        <option value="all">All Type</option>
        <option value="IN_SITE">IN SITE</option>
        <option value="OUT_SITE">OUT SITE</option>
      </select>
    </div>
    <div class="card-body">
      <slot v-if="momo_accounts && momo_accounts.data">
        <div class="table-responsive">
          <div class="list-info mb-3 d-flex">
            <div class="mr-auto">
              <span>{{ $trans('Results') }}: <strong>{{ momo_accounts.data.length }}</strong> {{
                  $trans('of')
                }} <strong>{{ momo_accounts.total }}</strong></span>
            </div>
            <div class="total-balance">
              <strong>{{ $trans('Total balance') }}:
                <span class="text-success">
                  <vue-numeric
                      currency-symbol-position="suffix"
                      :currency="'VND'"
                      v-bind:value="momo_total_balance"
                      v-bind:read-only="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </span>
              </strong>
            </div>
          </div>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>{{ $trans('Username') }}</th>
              <th>{{ $trans('Name') }}</th>
              <th class="text-right">{{ $trans('Balance') }}</th>
              <th class="text-right">{{ $trans('Deposit Limit (per month)') }}</th>
              <th class="text-right">{{ $trans('Deposit of Month') }}</th>
              <th class="text-right">{{ $trans('Payout of Month') }}</th>
              <th>{{ $trans('Deposit Status') }}</th>
              <th>{{ $trans('Account Status') }}</th>
              <th>{{ $trans('Last login') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(account,index) in list_momo_accounts" v-bind:key="index">
              <td>{{ account.username }}</td>
              <td>{{ account.fullname }}</td>
              <td class="text-right text-success">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.balance"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.deposit_limit"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>

              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.total_deposit_of_month"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    :currency="'VND'"
                    v-bind:value="account.total_payout_of_month"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td>
                <span v-if="account.deposit_status ==1"
                      class="badge badge-primary badge-pill">{{ $trans('Active') }}</span>
                <span v-if="account.deposit_status == 0"
                      class="badge badge-warning badge-pill">{{ $trans('Inactive') }}</span>
              </td>
              <td>
                <span v-if="account.status ==1" class="badge badge-primary badge-pill">{{ $trans('Logged') }}</span>
                <span v-if="account.status == 0"
                      class="badge badge-warning badge-pill">{{ $trans('Logged out') }}</span>
                <span v-if="account.status == -1" class="badge badge-warning badge-pill">{{ $trans('Inactive') }}</span>
              </td>
              <td>{{ account.last_login }}</td>
              <td class="text-right">
                <b-dropdown
                    split
                    split-variant="outline-primary"
                    variant="primary"
                    :text="$trans('Actions')"
                    class="m-2"
                    size="sm"
                >
                  <slot>
                    <b-dropdown-item @click="detail(account)" href="#">{{ $trans('Detail') }}</b-dropdown-item>
                    <b-dropdown-item v-if="$can('edit-momo-wallet')" @click="edit(account)" href="#">
                      {{ $trans('Edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$can('delete-momo-wallet')" @click="destroy(account)" href="#"><span
                        class="text-danger">{{ $trans('Delete') }}</span></b-dropdown-item>
                  </slot>
                </b-dropdown>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <paginate v-if="momo_accounts && momo_accounts.data && momo_accounts.last_page"
                  :page-count="momo_accounts.last_page"
                  :click-handler="paginate"
                  :prev-text="$trans('Previous')"
                  :next-text="$trans('Next')"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :page-link-class="'page-link'"
                  :prev-link-class="'page-link'"
                  :next-link-class="'page-link'"
                  :container-class="'pagination'">
        </paginate>
      </slot>
    </div>
    <modal v-if="addForm && $can('add-momo-wallet')" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add Wallet') }}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Username') }} (*)</label>
          <input v-model="addFields.username" class="form-control" type="text">
          <slot v-if="errors && errors['username']">
            <div class="text-danger" v-for="error in errors['username']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Password') }}</label>
          <input v-model="addFields.password" class="form-control" type="text">
          <slot v-if="errors && errors['password']">
            <div class="text-danger" v-for="error in errors['password']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Account name') }}</label>
          <input v-model="addFields.fullname" class="form-control" type="text">
          <slot v-if="errors && errors['fullname']">
            <div class="text-danger" v-for="error in errors['fullname']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Account Email') }}</label>
          <input v-model="addFields.email" class="form-control" type="text">
          <slot v-if="errors && errors['email']">
            <div class="text-danger" v-for="error in errors['email']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Type') }} (*)</label>
          <select class="form-control" v-model="addFields.type">
            <option value="IN_SITE">IN SITE</option>
            <option value="OUT_SITE">OUT SITE</option>
          </select>
          <slot v-if="errors && errors['type']">
            <div class="text-danger" v-for="error in errors['type']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Deposit Limit') }} (*)</label>
          <vue-numeric class="form-control"
                       v-bind:currency-symbol-position="'suffix'"
                       v-bind:currency="'VND'"
                       v-model="addFields.deposit_limit"
                       v-bind:read-only="false"
                       v-bind:precision="$get_precision()">
          </vue-numeric>
          <slot v-if="errors && errors['deposit_limit']">
            <div class="text-danger" v-for="error in errors['deposit_limit']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Deposit Status') }}</label>
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="add_deposit_status" type="checkbox"
                   v-model="addFields.deposit_status">
            <label class="form-check-label" for="add_deposit_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['deposit_status']">
            <div class="text-danger" v-for="error in errors['deposit_status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="store" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{ $trans('Add') }}
        </button>
      </slot>
    </modal>
    <modal v-if="editForm && $can('edit-momo-wallet')" v-bind:width="'500px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit Wallet') }}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="form-group">
          <label>{{ $trans('Username') }} (*)</label>
          <input disabled v-model="updateFields.username" class="form-control" type="text">
          <slot v-if="errors && errors['username']">
            <div class="text-danger" v-for="error in errors['username']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Password') }}</label>
          <input v-model="updateFields.password" class="form-control" type="text">
          <slot v-if="errors && errors['password']">
            <div class="text-danger" v-for="error in errors['password']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Account name') }}</label>
          <input v-model="updateFields.fullname" class="form-control" type="text">
          <slot v-if="errors && errors['fullname']">
            <div class="text-danger" v-for="error in errors['fullname']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Account Email') }}</label>
          <input v-model="updateFields.email" class="form-control" type="text">
          <slot v-if="errors && errors['email']">
            <div class="text-danger" v-for="error in errors['email']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Type') }} (*)</label>
          <select class="form-control" v-model="updateFields.type">
            <option value="IN_SITE">IN SITE</option>
            <option value="OUT_SITE">OUT SITE</option>
          </select>
          <slot v-if="errors && errors['type']">
            <div class="text-danger" v-for="error in errors['type']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Deposit Limit') }} (*)</label>
          <vue-numeric class="form-control"
                       v-bind:currency-symbol-position="'suffix'"
                       v-bind:currency="'VND'"
                       v-model="updateFields.deposit_limit"
                       v-bind:read-only="false"
                       v-bind:precision="$get_precision()">
          </vue-numeric>
          <slot v-if="errors && errors['deposit_limit']">
            <div class="text-danger" v-for="error in errors['deposit_limit']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <div class="form-group">
          <label>{{ $trans('Deposit Status') }}</label>
          <div class="form-check mb-1">
            <input true-value="1" false-value="0" id="update_deposit_status" type="checkbox"
                   v-model="updateFields.deposit_status">
            <label class="form-check-label" for="update_deposit_status">
              <span>{{ $trans('Active') }}</span>
            </label>
          </div>
          <slot v-if="errors && errors['deposit_status']">
            <div class="text-danger" v-for="error in errors['deposit_status']"
                 v-bind:key="error">
              <small>{{ error }}</small>
            </div>
          </slot>
        </div>
        <button @click="update" class="btn btn-primary">
          <i v-if="process" class="fa fa-spinner fa-spin"></i>
          {{ $trans('Update') }}
        </button>
      </slot>
    </modal>
    <modal v-if="showDetail && account" v-bind:width="'80%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Wallet Detail') }}
          <button @click="showDetail = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <div v-if="account.status != -1" class="alert alert-info">
          <strong>{{ $trans('Name') }}:</strong> {{ account.fullname }}<br>
          <strong>{{ $trans('Phone') }}:</strong> {{ account.username }}<br>
          <strong>{{ $trans('Email') }}:</strong> {{ account.email }}<br>
          <strong>{{ $trans('Balance') }}: </strong>
          <vue-numeric
              currency-symbol-position="suffix"
              :currency="'VND'"
              v-bind:value="account.balance"
              v-bind:read-only="true"
              v-bind:precision="0">
          </vue-numeric>
        </div>
        <slot v-if="account.type=='OUT_SITE'">
          <momo-history-db :keyword="account.username"></momo-history-db>
        </slot>
        <slot v-else>

          <slot v-if="account.status == -1">
            <div class="form-group">
              <label>{{ $trans('OTP') }} (*)</label>
              <input v-model="otpFields.otp" class="form-control" type="text">
              <slot v-if="errors && errors['otp']">
                <div class="text-danger" v-for="error in errors['otp']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <button @click="checkOtp(account)" class="btn btn-primary">
              <i v-if="process" class="fa fa-spinner fa-spin"></i>
              {{ $trans('Continue') }}
            </button>
          </slot>
          <slot v-if="account.status != -1">
            <nav class="mb-3">
              <div class="nav nav-tabs" id="nav-tab">
                <a class="nav-item nav-link" @click="tabActive = 'history'" v-bind:class="{'active':tabActive=='history'}"
                   href="javascript:void(0)">{{ $trans('History') }}</a>
                <a class="nav-item nav-link" @click="tabActive = 'transfer'"
                   v-bind:class="{'active':tabActive=='transfer'}" href="javascript:void(0)">{{ $trans('Transfer') }}</a>
                <a class="nav-item nav-link" @click="tabActive = 'Withdraw'"
                   v-bind:class="{'active':tabActive=='Withdraw'}" href="javascript:void(0)">{{ $trans('Withdraw') }}</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div v-if="tabActive == 'history'" class="tab-pane active">
                <momo-history v-bind:account_id="account._id"></momo-history>
              </div>
              <div v-if="tabActive == 'transfer'" class="tab-pane active">
                <momo-transfer v-bind:account_id="account._id"></momo-transfer>
              </div>
              <div v-if="tabActive == 'Withdraw'" class="tab-pane active">
                <div class="alert alert-success">
                  <h6 class="mb-2">{{ $trans('Bank connected') }}</h6>
                  <hr>
                  <strong>{{ $trans('Bank Account') }}:</strong> {{ connect_bank.bankAccount }}<br>
                  <strong>{{ $trans('Bank Name') }}:</strong> {{ connect_bank.bankName }}<br>
                  <strong>{{ $trans('Bank Verify Name') }}:</strong> {{ connect_bank.bankVerifyName }}<br>
                </div>
                <momo-withdraw v-bind:account_id="account._id"></momo-withdraw>
              </div>
            </div>
          </slot>
        </slot>

      </slot>
    </modal>

    <modal v-if="formHistories" v-bind:width="'80%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Histories') }}
          <button @click="formHistories = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <hr>
        <momo-history-db></momo-history-db>
      </slot>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "MomoWallet",
  data: function () {
    return {
      logForm: false,
      formHistories: false,
      tabActive: 'history',
      process: false,
      addFields: {
        username: '',
        password: '',
        deposit_limit: 0,
        deposit_status: 0,
        type: 'IN_SITE',
        fullname:'',
        email:'',
      },
      updateFields: {},
      errors: {},
      alert: null,
      addForm: false,
      filterQuery: {
        page: 1,
        type:'all',
        keyword:''
      },
      momo_accounts: null,
      account: null,
      histories: null,
      showDetail: false,
      otpFields: {
        otp: ''
      },
      momo_account_info: null,
      editForm: false,

      momo_total_balance: 0
    }
  },
  methods: {
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },

    index: function () {
      axios({url: this.$root.$data.api_url + '/api/momo/wallet', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.momo_accounts = resp.data.accounts;
            this.momo_total_balance = resp.data.total_balance;

          })
          .catch(err => {
            console.log(err)
          })
    },
    store: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({url: this.$root.$data.api_url + '/api/momo/wallet', data: this.addFields, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    edit: function (account) {
      this.editForm = true;
      axios({url: this.$root.$data.api_url + '/api/momo/wallet/' + account._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;

          })
          .catch(err => {
            console.log(err)
          })
    },
    update: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        axios({
          url: this.$root.$data.api_url + '/api/momo/wallet/' + this.updateFields._id,
          data: this.updateFields,
          method: 'PUT'
        })
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    destroy: function (account) {
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/momo/wallet/' + account._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail: function (account) {
      this.showDetail = true;
      this.account = null
      axios({url: this.$root.$data.api_url + '/api/momo/wallet/' + account._id, method: 'GET'})
          .then(resp => {
            this.account = resp.data.data;

          })
          .catch(err => {
            console.log(err)
          })
    },
    checkOtp: function (account) {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.otpFields
        data.id = account._id
        axios({url: this.$root.$data.api_url + '/api/momo/wallet/check_otp', data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.detail(account);
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
  },
  created() {
    this.index()
    // Real time
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('momo-wallet-channel');
    channel.bind('momo-wallet-event', (e) => {
      if (this.momo_accounts && this.momo_accounts.data) {
        var arr = this.momo_accounts.data;
        var tmp_arr = [];
        var current_id = e.momo_account._id;
        arr.forEach(function (el, index) {
          if (current_id == el._id) {

            let item = el
            item['balance'] = e.momo_account.balance;
            item['status'] = e.momo_account.status;
            item['deposit_status'] = e.momo_account.deposit_status;
            item['last_login'] = e.momo_account.last_login;

            tmp_arr[index] = item;
          } else {
            tmp_arr[index] = el;
          }
        });
        this.momo_accounts.data = tmp_arr;
      }

    });
  },
  computed: {
    connect_bank: function () {
      if (!this.account) {
        return {}
      }
      let data_account = this.account.data_account
      let momoMsg = data_account.momoMsg
      return {
        bankAccount: momoMsg.bankAccount,
        bankName: momoMsg.bankName,
        bankVerifyName: momoMsg.bankVerifyName
      }
    },
    list_momo_accounts: function () {
      if (!this.momo_accounts) {
        return null
      }
      if (!this.momo_accounts.data || this.momo_accounts.data.length < 0) {
        return null
      }
      return this.momo_accounts.data
    }
  }
}

</script>

<style scoped>

</style>