<template>
  <div v-if="$can('payout-settings')" class="card">
    <div class="card-body">
      <div class="d-flex">
        <h4 class="mb-0">{{$trans('Payout settings')}}</h4>
      </div>
      <hr>
      <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
        {{ alert.message }}
      </div>
      <div class="form-group">
        <label>{{ $trans('Minimum amount for payout') }}</label>
        <vue-numeric class="form-control"
                     v-bind:currency-symbol-position="'suffix'"
                     v-bind:currency="this.$root.current_currency"
                     v-model="settings.minimum_amount_for_payout"
                     v-bind:read-only="false"
                     v-bind:precision="$get_precision()">
        </vue-numeric>
      </div>
      <div class="form-group">
        <label>{{ $trans('Maximum amount for payout') }}</label>
        <vue-numeric class="form-control"
                     v-bind:currency-symbol-position="'suffix'"
                     v-bind:currency="this.$root.current_currency"
                     v-model="settings.maximum_amount_for_payout"
                     v-bind:read-only="false"
                     v-bind:precision="$get_precision()">
        </vue-numeric>
        <small>{{$trans('If the value is zero, the default is unlimited.')}}</small>
      </div>
      <button @click="save_settings" class="btn btn-primary">
        <i v-if="process" class="fa fa-spinner fa-spin"></i>
        {{ $trans('Save') }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Payout",
    data: function () {
      return {
        settings: {},
        process: false,
        alert: null,
        errors: {},
      }
    },
    methods: {
      get_settings: function () {
        axios({url: this.$root.$data.api_url + '/api/settings/payout', params: {}, method: 'GET'})
            .then(resp => {
              this.settings = resp.data;
            })
            .catch(err => {
              console.log(err)
            })
      },
      save_settings: function () {
        this.alert = null
        if (this.process == false) {
          this.process = true;
          let data = this.settings;
          axios({url: this.$root.$data.api_url + '/api/settings/payout', data: data, method: 'PUT'})
              .then(resp => {
                let error_code = resp.data.error_code;
                if (error_code == 0) {
                  this.alert = {
                    type: 'success',
                    message: resp.data.message
                  }
                  this.get_settings();
                } else {
                  this.alert = {
                    type: 'warning',
                    message: resp.data.message
                  }
                  this.errors = resp.data.errors || {};
                }
                this.process = false;

              })
              .catch(err => {
                console.log(err)
                this.process = false;
              })
        }
      },
    },
  created() {
    this.get_settings();
  },
}
</script>

<style scoped>

</style>