<template>
  <div class="Notification">
    <div class="card">
      <div class="card-header d-flex">
        <h2 class="mr-auto ">{{$trans('Notification')}}</h2>
      </div>
      <div class="card-body">
        <slot v-if="notifications && notifications.data">
          <div class="table-responsive">
            <div class="list-info mb-3">
              <small>{{$trans('Results')}}: <strong>{{notifications.data.length}}</strong> {{$trans('of')}} <strong>{{notifications.total}}</strong></small>
            </div>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>{{$trans('Ref Number')}}</th>
                <th>{{$trans('Amount')}}</th>
                <th>{{$trans('Sender')}}</th>
                <th>{{$trans('Notification content')}}</th>
                <th>{{$trans('Extend Infos')}}</th>
                <th>{{$trans('Date')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(notification,index) in notifications.data" v-bind:key="index">
                <td>{{notification.ref_number}}</td>
                <td>
                  <vue-numeric v-if="notification.amount"
                               currency-symbol-position="suffix"
                               :currency="current_currency"
                               v-bind:value="notification.amount"
                               v-bind:read-only="true"
                               v-bind:precision="0">
                  </vue-numeric>
                </td>
                <td>{{notification.sender}}</td>
                <td>{{notification.notification_text}}</td>
                <td>
                  <slot v-if="notification.extend_infos"></slot>
                  <slot v-else>-</slot>
                </td>
                <td>{{notification.created_at}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginate v-if="notifications && notifications.data && notifications.last_page"
                    :page-count="notifications.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </slot>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pusher from "pusher-js";

export default {
  name: "Notification",
  data:function (){
    return {
      filterQuery:{
        page:1
      },
      notifications:null,
      current_currency: this.$root.current_currency,
    }
  },
  methods:{
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    index:function () {
      axios({url: this.$root.$data.api_url + '/api/notification',params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.notifications = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
  },
  created() {
      this.index()
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY,
        {
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          encrypted: true
        }
    );
    const channel = pusher.subscribe('notification-channel');
    channel.bind('notification-event', (e) => {
      console.log(e)
      if (this.notifications && this.notifications.data) {
        if (e.action == 'created') {
          this.notifications.data.unshift(e.notification);
        } else {
          var arr = this.notifications.data;
          var tmp_arr = [];
          var current_id = e.notification._id;
          arr.forEach(function (el, index) {
            if (current_id == el._id) {
              tmp_arr[index] = e.notification;
            } else {
              tmp_arr[index] = el;
            }
          });
          this.notifications.data = tmp_arr;
        }
      }
    });
  }
}
</script>

<style scoped>

</style>